<template>
    <lee-page bg-img="/images/bg_head_9.jpg" type="light" title-class="margin-top-100" height="280" title="冷藏车相关数据" :bread="[{title:'行业数据',url:'/hydata'},{title:'冷藏车'}]">
        <div class="bg-img-2 padding-bottom-400 padding-top-50" :style="{backgroundColor:'#f8f9fa'}">
            <check-vip akey="hangyedata_car">
                <div class="wrap1100">
                    <lee-year-tab :list="yearList"></lee-year-tab>
                    <div class="box-shadow padding-30 bg-light margin-top-30">
                        <div class="green-line">
                            <div class="cl f34">
                                <lee-img-icon url="/images/icon/icon_31.png" size="30" color="#7ED321" class="z margin-right" v-if="!$isMobile"></lee-img-icon>
                                <div class="z" :class="$isMobile ? 'f18 margin-top dark-2 block' : 'f22'">冷藏车相关数据</div>
                            </div>
                            <!--                        <el-row :gutter="20" class="dark-3 margin-top" :class="$isMobile ? 'f12' : 'f14'">-->
                            <!--                            <el-col :md="5" :xs="12">发布机构：湖北省政府</el-col>-->
                            <!--                            <el-col :md="5" :xs="12">发布时间：2020-03-26</el-col>-->
                            <!--                            <el-col :md="5" :xs="12">文号：国药监注[2019]33号</el-col>-->
                            <!--                        </el-row>-->
                        </div>
                        <div class="f16 dark-2">
<!--                            <p>重点企业冷藏车总量：</p>-->
<!--                            <div class="text-center">-->
<!--                                <lee-bar-echarts-->
<!--                                    ref="bar5"-->
<!--                                    :grid-bottom="30"-->
<!--                                    height="350px"-->
<!--                                    show-legend-->
<!--                                ></lee-bar-echarts>-->
<!--                            </div>-->
                            <p v-if="data && data.count">据中物联冷链委统计，{{nowYear}}年全国冷藏车市场保有量达到{{data.count.now.total}}辆<template v-if="data.count.last">，较上年{{data.count.now.rate >= 0 ? '增长' : '减少'}}{{Math.abs(parseInt(data.count.now.total - data.count.last.total))}}辆，同比{{data.count.now.rate >= 0 ? '增长' : '减少'}}{{data.count.now.rate}}%</template><template v-else>。</template></p>
                            <div class="text-center">
                                <lee-bar-echarts
                                    ref="bar1"
                                    :grid-bottom="$isMobile ? 50 : 30"
                                    :height="$isMobile ? '250px' : '350px'"
                                    show-legend
                                ></lee-bar-echarts>
                            </div>
                          <p v-if="areaSort.length > 0">从区域分布来看，{{areaSort[0].name}}地区冷藏车销量增长最高，其次是{{areaSort[1].name}}。</p>
<!--                            <p>从区域分布来看，华东地区冷藏⻋增⻓量最⾼，其次是华南、华北和华中。</p>-->
<!--                            <p>从区域分布来看，华东地区冷藏车增长量最高，其次是华南、华北、华中地区，增长量占比基本接近，东北地区增长量占比较去年有所提升，西北、西南地区增长量较少。经济发展不平衡以及区域气候差异等是造成我国冷藏车增长量分布不均衡的主要原因。今年整体冷藏车保有量增长趋于平衡，华东地区增长放缓，西北、西南地区增长加快。拉近分布不均的情况，但依旧差距很大。</p>-->
                            <div class="text-center">
                                <el-row>
                                    <el-col :md="10" :xs="24">
                                        <lee-pie-echarts
                                            ref="pie1"
                                            :grid-bottom="$isMobile ? 100 : 30"
                                            :height="$isMobile ? '300px' : '350px'"
                                            :show-legend="false"
                                            label-format="{b}:{d}%"
                                            label-pos="outside"
                                            :radius="['30%','50%']"></lee-pie-echarts>
                                    </el-col>
                                    <el-col :md="14" :xs="24">
                                        <lee-bar-echarts-hor
                                            ref="bar2"
                                            :grid-bottom="$isMobile ? 50 : 30"
                                            :height="$isMobile ? '250px' : '350px'"
                                            show-legend
                                            :legend-orient="$isMobile ? 'horizontal' : 'vertical'"
                                            :legend-pos="$isMobile ? 'bottom' : 'right'"
                                            :show-left="true"
                                            :show-right="false"
                                        ></lee-bar-echarts-hor>
                                    </el-col>
                                </el-row>
                            </div>
                            <p v-if="data">从⻋型来看，{{nowYear}}年重型、中型、轻型、微型冷藏⻋销量分别为{{ data.type.now.heavy }}辆、{{ data.type.now.small }} 辆、{{ data.type.now.light }}辆、{{ data.type.now.mini }}辆。</p>
<!--                            <p v-if="data">从车型来看，{{nowYear}}年重型、中型、轻型、微型冷藏车增长量分别约为{{data.type.now.heavy}}辆、{{data.type.now.small}}辆、{{data.type.now.light}}辆、{{data.type.now.mini}}辆。如图4-5 所示，占总增量的比例分别为{{ ((data.type.now.heavy / data.type.now.total) * 100).toFixed(2)}}%、{{ ((data.type.now.small / data.type.now.total) * 100).toFixed(2)}}%、{{ ((data.type.now.light / data.type.now.total) * 100).toFixed(2)}}%、{{ ((data.type.now.mini / data.type.now.total) * 100).toFixed(2)}}%，总体来看作为城市冷链配送的主力轻型冷藏车销量仍居于首位，与订单碎片化、配送及时性等客户需求方式改变有很大关系。据统计，{{nowYear}}年冷藏车畅销车型尺寸排在前3位的分别为4.2米、9.6米、6.8米，13.75米冷藏车增量也比较明显。</p>-->
                            <div class="text-center">
                                <lee-bar-echarts
                                    ref="bar3"
                                    :show-left="false"
                                    :show-right="false"
                                    :grid-bottom="$isMobile ? 50 : 30"
                                    :height="$isMobile ? '250px' : '350px'"
                                    :show-legend="false"
                                ></lee-bar-echarts>
                            </div>
                            <p v-if="data">
                                从燃料来看，{{nowYear}}全年柴油、汽油、天然⽓、电动冷藏⻋销量增长量分别为{{ data.oil.now.chaiyou}}辆、{{ data.oil.now.qiyou}}辆、{{ data.oil.now.tianranqi}}辆、{{data.oil.now.diandong}}辆。
                            </p>
<!--                            <p v-if="data">从燃料来看，{{nowYear}}全年柴油、汽油、天然气、电动冷藏车增长量分别约为{{data.oil.now.chaiyou}}辆、{{data.oil.now.qiyou}}辆、{{data.oil.now.tianranqi}}辆、{{data.oil.now.diandong}}辆，占总增长量的比例分别为{{ ((data.oil.now.chaiyou / data.oil.now.total) * 100).toFixed(2)}}%、{{ ((data.oil.now.qiyou / data.oil.now.total) * 100).toFixed(2)}}%、{{ ((data.oil.now.tianranqi / data.oil.now.total) * 100).toFixed(2)}}%、{{ ((data.oil.now.diandong / data.oil.now.total) * 100).toFixed(2)}}%，柴油以近90%的占比占据当前冷藏车燃料的重要角色。新能源燃料市场较为薄弱，新能源冷藏车的产销较惨淡，与整体蓬勃向上发展的新能源专用车市场形成鲜明对比，并没有呈现出较好的市场表现力，与政策补贴降低、购车成本高、续航里程短、额定载质量较小、使用天然气故障率较高等原因有关。</p>-->
                            <div class="text-center">
                                <lee-bar-echarts
                                    ref="bar4"
                                    :grid-bottom="$isMobile ? 50 : 30"
                                    :height="$isMobile ? '250px' : '350px'"
                                    :show-left="false"
                                    :show-right="false"
                                    :show-legend="false"
                                    :data="[
                                    {name:'柴油',type:'bar',color:'green',data:[3740,4200,4750,5238]}]"
                                    :x-axis="['柴油', '汽油', '天然气', '电动']"
                                ></lee-bar-echarts>
                            </div>
                        </div>
                    </div>
                </div>
            </check-vip>
        </div>
    </lee-page>
</template>

<script>
export default {
    name: "List",
    data(){
        return{
            yearList:[],
            nowYear:'',

            startYear:'',
            endYear:'',

            data:null,
            bardata:null,

          areaSort:[]
        }
    },
    created() {
        // let _now = new Date();
        // let _startYear = _now.getFullYear() - 1;
        // let _endYear = _startYear - 8;
        //
        // this.startYear = _startYear;
        //
        // // for (let i = _startYear;i > _endYear;i--){
        // //     this.yearList.push({label:i+'年',value:i});
        // // }
        //
        // this.endYear = this.yearList[this.yearList.length - 1].value;
        // this.yearRange = [_startYear-4,_startYear];

        let _this = this;

        let _now = new Date();

        this.$nextTick(()=>{
            // _this.nowYear = _this.$route.query.year ? parseInt(_this.$route.query.year) : (_this.yearList.length > 0 ? _this.yearList[0].value : _now.getFullYear() - 1);
        })
        // this.nowYear = this.$route.query.year ? this.$route.query.year : this.yearList[0].value;
        this.nowYear = this.$route.query.year ? this.$route.query.year : '';

        // console.log(this.nowYear);
    },
    mounted() {
        // this.getAll(this.nowYear);
        this.getYears();
    },
    methods:{
        // changeTab(v){
        //     // this.nowYear = v.value;
        //     // this.getAll(this.nowYear);
        // },
        //
        // getAll(year){
        //     for(let i in this){
        //         if(i.indexOf('getTotal') === 0){
        //             this[i](year);
        //         }
        //     }
        // },

        getYears() {
            let _this = this;

            this.$http.post({
                url: '/HydataColdCar/getYears',
                data:null,
                success(res) {
                    for (let i = 0;i < res.length;i++){
                        _this.yearList.push({label:res[i]+'年',value:res[i]});
                    }

                    _this.nowYear = _this.nowYear ? _this.nowYear : res[0];

                    _this.endYear = res[res.length - 1];

                    _this.getTotal1(_this.nowYear);
                }
            });
        },

        getTotal1(year){
            let _this = this;
            this.$http.post({
                url:'/HydataColdCar/getData',
                data:{year:year,yearlist:[year,year - 4]},
                success(res){
                    _this.data = res;

                    let _bardata = {bar:[],line:[],xaxis:[]};

                    for(let i=0;i < res.count.list.length;i++) {
                        _bardata.bar.push(res.count.list[i].total);
                        _bardata.line.push(res.count.list[i].rate);
                        _bardata.xaxis.push(res.count.list[i].year.toString());
                    }

                    if(res.count.list.length > 0) {
                        _this.bardata = _bardata;
                    }

                    let _pie1Data = [];

                    _pie1Data.push({value:res.area.now.huazhong,name:'华中'});
                    _pie1Data.push({value:res.area.now.huadong,name:'华东'});
                    _pie1Data.push({value:res.area.now.huanan,name:'华南'});
                    _pie1Data.push({value:res.area.now.huabei,name:'华北'});
                    _pie1Data.push({value:res.area.now.dongbei,name:'东北'});
                    _pie1Data.push({value:res.area.now.xinan,name:'西南'});
                    _pie1Data.push({value:res.area.now.xibei,name:'西北'});

                    _this.areaSort.push({name:'华中',value:parseInt(res.area.now.huazhong - res.area.last.huazhong)});
                  _this.areaSort.push({name:'华东',value:parseInt(res.area.now.huadong - res.area.last.huadong)});
                  _this.areaSort.push({name:'华南',value:parseInt(res.area.now.huanan - res.area.last.huanan)});
                  _this.areaSort.push({name:'华北',value:parseInt(res.area.now.huabei - res.area.last.huabei)});
                  _this.areaSort.push({name:'东北',value:parseInt(res.area.now.dongbei - res.area.last.dongbei)});
                  _this.areaSort.push({name:'西南',value:parseInt(res.area.now.xinan - res.area.last.xinan)});
                  _this.areaSort.push({name:'西北',value:parseInt(res.area.now.xibei - res.area.last.xibei)});

                  _this.areaSort.sort(function (a,b){
                    return b.value - a.value;
                  })

                    let _bar2data = {bar:[],bar2:[],xaxis:[]};

                    _bar2data.bar.push(res.area.now.huazhong);
                    _bar2data.bar.push(res.area.now.huadong);
                    _bar2data.bar.push(res.area.now.huanan);
                    _bar2data.bar.push(res.area.now.huabei);
                    _bar2data.bar.push(res.area.now.dongbei);
                    _bar2data.bar.push(res.area.now.xinan);
                    _bar2data.bar.push(res.area.now.xibei);

                    _bar2data.bar2.push(res.area.last.huazhong);
                    _bar2data.bar2.push(res.area.last.huadong);
                    _bar2data.bar2.push(res.area.last.huanan);
                    _bar2data.bar2.push(res.area.last.huabei);
                    _bar2data.bar2.push(res.area.last.dongbei);
                    _bar2data.bar2.push(res.area.last.xinan);
                    _bar2data.bar2.push(res.area.last.xibei);

                    let _bar5data = {bar:[],xaxis:[]};

                    for(let i=0;i < res.baiqiang_car.list.length;i++) {
                        _bar5data.bar.push(res.baiqiang_car.list[i].car_total);
                        _bar5data.xaxis.push(res.baiqiang_car.list[i].year.toString());
                    }

                    if(_this.$refs.bar5) {
                        _this.$refs.bar5.initData(
                            [
                                {name: '重点企业冷藏车总量', type: 'bar', color: 'green', data: _bar5data.bar,dw:'辆'},
                            ],
                            _bar5data.xaxis
                        );
                    }

                    if(_this.$refs.bar1) {
                        _this.$refs.bar1.initData(
                            [
                                {name: '保有量', type: 'bar', color: 'green', data: _bardata.bar,dw:'辆'},
                                {name: '增长率', type: 'line', color: 'purple', data: _bardata.line,dw:'%'},
                            ],
                            _bardata.xaxis
                        );
                    }

                    if(_this.$refs.pie1) {
                        _this.$refs.pie1.initData(_pie1Data);
                    }

                    if(_this.$refs.bar2) {
                        _this.$refs.bar2.initData(
                            [
                                {name: res.area.now.year.toString(), type: 'bar', color: 'green', data: _bar2data.bar,dw:'辆'},
                                {name: res.area.last.year.toString(), type: 'bar', color: 'blue', data: _bar2data.bar2,dw:'辆'},
                            ],
                            ['华中','华东','华南','华北','东北','西南','西北']
                        );
                    }

                    let _bar3data = {bar:[],xaxis:[]};
                    _bar3data.bar.push(res.type.now.heavy);
                    _bar3data.bar.push(res.type.now.small);
                    _bar3data.bar.push(res.type.now.light);
                    _bar3data.bar.push(res.type.now.mini);

                    if(_this.$refs.bar3) {
                        _this.$refs.bar3.initData(
                            [
                                {name: '', type: 'bar', color: 'most', data: _bar3data.bar,dw:'辆'},
                            ],
                            ['重型','中型','轻型','微型']
                        );
                    }

                    let _bar4data = {bar:[],xaxis:[]};
                    _bar4data.bar.push(res.oil.now.chaiyou);
                    _bar4data.bar.push(res.oil.now.qiyou);
                    _bar4data.bar.push(res.oil.now.tianranqi);
                    _bar4data.bar.push(res.oil.now.diandong);

                    if(_this.$refs.bar4) {
                        _this.$refs.bar4.initData(
                            [
                                {name: '', type: 'bar', color: 'green', data: _bar4data.bar,dw:'辆'},
                            ],
                            ['柴油','汽油','天然气','电动']
                        );
                    }
                }
            })
        },
    }
}
</script>
