import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Moment from 'moment';

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './assets/main.css'
import './assets/iconfont.css'
import 'font-awesome/css/font-awesome.min.css'

import Config from './script/config';
import Utils from './script/utils';
import User from './script/user';
import Http from './script/http';
import Api from './script/api';
import EchartsJs from './echartScript/echartsJs';
import EchartsJs2 from './echartScript/echartsJs2';
import RegExp from './script/RegExp';

// import VConsole from 'vconsole/dist/vconsole.min.js'
// let vConsole = new VConsole();

import ECharts from 'vue-echarts'

Vue.use(ElementUI);

//components
import leeHeader from './components/Header';
import leePage from './components/Page';
import BlockItem from './components/item/BlockItem';
import Select from './components/Select';
import Input from './components/Input';
import Search from './components/Search';
import ImgIcon from './components/ImgIcon';
import headTItle from './components/headTItle';
import MapHot from './components/MapHot';
import FileDown from "@/components/FileDown";
import GoodView from "@/components/GoodView";
import UpDownTag from "@/components/UpDownTag";
import CommentView from "@/components/CommentView";
import HashTab from "@/components/HashTab";
import YearTab from "@/components/YearTab";
import StateTag from "@/components/StateTag";
import UserMenu from "@/components/UserMenu";
import UserPage from "@/components/UserPage";
import FormCell from '@/components/Cell';
import UserTopTab from "@/components/UserTopTab";
import Swiper from "@/components/Swiper";
import TimeLine from "@/components/TimeLine";
import PayView from "@/components/PayView";
import BarEcharts1 from "@/components/BarEcharts1";
import BarEcharts2 from "@/components/BarEcharts2";
import BarEcharts from "@/components/BarEcharts";
import PieEcharts from "@/components/PieEcharts";
import BarEchartsHor from "@/components/BarEchartsHor";
import ChinaEcharts from "@/components/ChinaEcharts";
import FooterFix from "@/components/FooterFix";
import UserMainPage from "@/components/UserMainPage";
import SafeProgress from "@/components/SafeProgress";
import BaseCell from "@/components/BaseCell";
import AddOrder from "@/components/AddOrder";
import LeeTabs from "@/components/Tabs";

Vue.component('lee-header', leeHeader);
Vue.component('lee-page', leePage);
Vue.component('BlockItem', BlockItem);
Vue.component('lee-select', Select);
Vue.component('lee-input', Input);
Vue.component('lee-search', Search);
Vue.component('lee-img-icon', ImgIcon);
Vue.component('lee-head-title', headTItle);
Vue.component('lee-map-hot', MapHot);
Vue.component('lee-file-down', FileDown);
Vue.component('lee-good-view', GoodView);
Vue.component('lee-updown-tag', UpDownTag);
Vue.component('lee-comment-view', CommentView);
Vue.component('lee-hash-tab', HashTab);
Vue.component('lee-year-tab', YearTab);
Vue.component('lee-state-tag', StateTag);
Vue.component('lee-user-menu', UserMenu);
Vue.component('lee-user-page', UserPage);
Vue.component('lee-cell', FormCell);
Vue.component('lee-user-top-tab', UserTopTab);
Vue.component('lee-swiper', Swiper);
Vue.component('lee-time-line', TimeLine);
Vue.component('lee-pay-view', PayView);
Vue.component('lee-echarts', ECharts)
Vue.component('lee-bar-echarts-1', BarEcharts1);
Vue.component('lee-bar-echarts-2', BarEcharts2);
Vue.component('lee-new-pie-echarts-2', NewPieEcharts2);
Vue.component('lee-bar-echarts', BarEcharts);
Vue.component('lee-pie-echarts', PieEcharts);
Vue.component('lee-bar-echarts-hor', BarEchartsHor);
Vue.component('lee-bar-echarts-hor-two', BarEchartsHorTwo);
Vue.component('lee-china-echarts', ChinaEcharts);
Vue.component('lee-footer-fix', FooterFix);
Vue.component('lee-user-main-page', UserMainPage);
Vue.component('lee-safe-progress', SafeProgress);
Vue.component('lee-base-cell', BaseCell);
Vue.component('lee-add-order', AddOrder);
Vue.component('lee-index-hydata', IndexHyData);
Vue.component('lee-index-hydata1', IndexHyData1);
Vue.component('lee-index-hydata2', IndexHyData2);
Vue.component('lee-index-hydata3', IndexHyData3);
Vue.component('lee-index-hydata4', IndexHyData4);
Vue.component('lee-index-hydata5', IndexHyData5);
Vue.component('lee-index-hydata6', IndexHyData6);
Vue.component('lee-index-hydata7', IndexHyData7);
Vue.component('lee-type-select', TypeSelect);
Vue.component('check-vip', CheckVip);
Vue.component('lee-tabs', LeeTabs);
Vue.component('head-img-upload', HeadImgUpload);
Vue.component('login-button', LoginButton);
Vue.component('login-dialog', LoginDialog);
Vue.component('lee-vip-file-down', VipFileDown);
Vue.component('vip-check-button', VipCheckButton);
Vue.component('image-pre-view',ImagePreView);
Vue.component('image-upload',ImageUpload);
Vue.component('file-upload',FileUpload);

import IndexHyData from "@/components/IndexHyData";
import IndexHyData1 from "@/components/IndexHyData1";
import IndexHyData2 from "@/components/IndexHyData2";
import IndexHyData3 from "@/components/IndexHyData3";
import IndexHyData4 from "@/components/IndexHyData4";
import IndexHyData5 from "@/components/IndexHyData5";
import IndexHyData6 from "@/components/IndexHyData6";
import IndexHyData7 from "@/components/IndexHyData7";
import TypeSelect from "@/components/TypeSelect";
import CheckVip from "@/components/CheckVip";
import BarEchartsHorTwo from "@/components/BarEchartsHorTwo";
import HeadImgUpload from "@/components/HeadImgUpload";
import LoginButton from "@/components/LoginButton";
import LoginDialog from "@/components/LoginDialog";
import VipFileDown from "@/components/VipFileDown";
import VipCheckButton from "@/components/VipCheckButton";
import ImagePreView from './components/ImagePreView'
import ImageUpload from './components/ImageUpload'
import FileUpload from './components/FileUpload'
import NewPieEcharts2 from './components/NewPieEcharts2'

let echarts = require('echarts');
let chinaData = require('./assets/json/china.json')

router.beforeEach((to, from, next) => {
	if(Utils.isMobile().versions.mobile){
		if(to.path === '/miniqrcode'){
			next();
		}else {
			next('/miniqrcode');
		}
		// let _arr = ['/mobile/login','/order/paydone'];
		//
		// if(_arr.indexOf(to.path) >= 0){
		// 	next();
		// }else{
		// 	let _token = User.getToken();
		//
		// 	if(_token) {
		// 		next();
		// 	}else{
		// 		let _openid = to.query.openid ? to.query.openid : '';
		// 		if(_openid){
		// 			Http.post({
		// 				url: 'auth/login',
		// 				data: {openid: _openid,password:_openid},
		// 				success(res) {
		// 					// console.log(res);
		// 					// _this.logined = true;
		//
		// 					User.setToken(res.token);
		// 					User.setUid(res.id);
		// 					User.setMobile(res.mobile ? res.mobile : '');
		// 					User.setOpenId(res.openid ? res.openid : '');
		// 					User.setAvatar(res.avatar ? res.avatar : '');
		// 					User.setUserName(res.username ? res.username : '');
		// 					// User.setToken(res.token);
		// 					User.setEmail(res.email ? res.email : '');
		// 					User.setContact(res.contact ? res.contact : '');
		// 					User.setSex(res.sex ? res.sex : '');
		// 					User.setNickName(res.nickname ? res.nickname : '');
		//
		// 					next();
		// 				}
		// 			})
		// 		}else {
		// 			let _location = window.location;
		//
		// 			let _fromUrl = _location.href + (_location.search ? '&from=wx' : '?from=wx');
		//
		// 			let _url = Config.apiUrl+'wx/getOpenid?url='+_fromUrl;
		// 			window.location.replace(_url);
		// 		}
		// 	}
		// }
	}else {
		if(to.path === '/miniqrcode'){
			next('/');
		}
		let _notToken = ['/login', '/register', '/qrlogin', '/findpwd'];
		let _belogin = ['/qa/me'];

		if (User.getToken()) {
			if (_notToken.indexOf(to.path) >= 0) {
				next('/');
			}else{
				next();
			}
		}else{
			if (to.path.indexOf('/user') === 0 || _belogin.indexOf(to.path) >= 0) {
				ElementUI.Message.error('请先登录');
				next('/login');
			}else{
				next();
			}
		}
		// if (_notToken.indexOf(to.path) >= 0) {
		// 	if (User.getToken()) {
		// 		next('/');
		// 	} else {
		// 		next();
		// 	}
		// } else {
		// 	if (to.path.indexOf('/user') === 0) {
		// 		if (User.getToken()) {
		// 			next();
		// 		} else {
		// 			ElementUI.Message.error('请先登录');
		// 			next('/login');
		// 		}
		// 	} else {
		// 		if(to.path === '/qa/me'){
		// 			if (User.getToken()) {
		// 				next();
		// 			} else {
		// 				ElementUI.Message.error('请先登录');
		// 				next('/login');
		// 			}
		// 		}else {
		// 			next();
		// 		}
		// 	}
		// }
	}
});

Vue.config.productionTip = false

Vue.prototype.$echarts = echarts;
Vue.prototype.$china = chinaData;
Vue.prototype.$moment = Moment;
Vue.prototype.$config = Config;
Vue.prototype.$utils = Utils;
Vue.prototype.$user = User;
Vue.prototype.$echartsJs = EchartsJs;
Vue.prototype.$echartsJs2 = EchartsJs2;
Vue.prototype.$http = Http;
Vue.prototype.$api = Api;
// Vue.prototype.$toast = Toast;
Vue.prototype.$regexp = RegExp;

let browser = Utils.isMobile();
// Vue.prototype.$isMobile = browser.versions.mobile;
Vue.prototype.$isMobile = false;

new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')
