<template>
    <el-select v-model="value" :placeholder="placeholder" @change="change" class="lee-select bg-gray-4 border-radius-5 vm">
        <el-option
            clearable
            v-for="item in optionList"
            :key="item.value"
            :label="item.text"
            :value="item.value">
        </el-option>
    </el-select>
</template>

<script>
export default {
    name: "TypeSelect",
    data(){
        return{
            value:'',
            optionList:[]
        }
    },
    model:{
        prop:'values',
        event:'change'
    },
    props:{
        values:{
            type:[String,Number],
            default:''
        },
        placeholder:{
            type:String,
            default:'请选择'
        },
        akey:{
            type:String,
            default:''
        }
    },
    created() {
        this.value = this.values;
    },
    mounted() {
        this.getList();
    },
    methods:{
        change(e){
            this.$emit('change',e);
        },
        getList(){
            let _this = this;
            this.$http.post({
                url: '/Type/listAll',
                data: {akey:this.akey,orderBy:'sort',sortBy:'asc'},
                success(res) {
                    let _op = [];

                    if(_this.placeholder !== '请选择'){
                        _op.push({text:_this.placeholder,value:''})
                    }

                    for (let i=0;i < res.length;i++){
                        _op.push({text:res[i].name,value:res[i].id});
                    }
                    _this.optionList = _op;
                }
            });
        },
    },
    watch:{
        values(n,o){
            this.value = n;
        }
    }
}
</script>
