<template>
    <lee-page bg-img="/images/bg_head_9.jpg" type="light" title-class="margin-top-100" height="280" title="展示柜相关数据" :bread="[{title:'行业数据',url:'/hydata'},{title:'展示柜'}]">
        <div class="bg-img-2 padding-bottom-400 padding-top-50" :style="{backgroundColor:'#f8f9fa'}">
            <check-vip akey="hangyedata_zhanshigui">
                <div class="wrap1100">
                    <lee-year-tab :list="yearList"></lee-year-tab>
                    <div class="box-shadow padding-30 bg-light margin-top-30">
                        <div class="green-line">
                            <div class="cl f34">
                                <lee-img-icon url="/images/icon/icon_31.png" size="30" color="#7ED321" class="z margin-right" v-if="!$isMobile"></lee-img-icon>
                                <div class="z" :class="$isMobile ? 'f18 margin-top dark-2 block' : 'f22'">展示柜相关数据</div>
                            </div>
                        </div>
                        <div class="f16 dark-2">
                            <p class="text-center f18">{{nowYear-4}}年 - {{nowYear}}年我国陈列柜行业销售规模走势</p>
                            <el-row :gutter="10">
                                <el-col :span="12">
                                    <div class="text-center">
                                        <lee-bar-echarts
                                            ref="bar1"
                                            :show-right="false"
                                            :show-title="false"
                                            title-pos="bottom"
                                            :grid-bottom="30"
                                            height="350px"
                                            :show-legend="true"></lee-bar-echarts>
                                    </div>
                                </el-col>
                                <el-col :span="12">
                                    <div class="text-center">
                                        <lee-bar-echarts
                                            ref="bar2"
                                            :show-right="false"
                                            :show-title="false"
                                            title-pos="bottom"
                                            :grid-bottom="30"
                                            height="350px"
                                            :show-legend="true"></lee-bar-echarts>
                                    </div>
                                </el-col>
                            </el-row>
                        </div>
                        <div class="margin-top-50">
                            <el-row :gutter="10">
                                <el-col :span="12">
                                    <div class="text-center">
                                    <lee-pie-echarts
                                        :title="nowYear+'年按销售量远置式陈列柜占比'"
                                    ref="pie1"
                                    :grid-bottom="30"
                                    height="350px"
                                    :label-format="'{b}\n{d}%'"
                                    label-pos="outside"
                                    legend-orient="horizontal"
                                    legend-pos="bottom"
                                    :radius="['0%','60%']"></lee-pie-echarts>
                                    </div>
                                </el-col>
                                <el-col :span="12">
                                    <div class="text-center">
                                    <lee-bar-echarts-hor
                                        :title="(nowYear - 4)+'年 - '+nowYear+'年陈列柜产品结构(按销量)'"
                                        ref="bar4"
                                        :show-right="false"
                                        :show-title="true"
                                        title-pos="top"
                                        :grid-top="30"
                                        height="350px"
                                        :show-legend="true"></lee-bar-echarts-hor>
                                    </div>
                                </el-col>
                            </el-row>
                        </div>
                        <div class="margin-top-50">
                            <el-row :gutter="10">
                                <el-col :span="12">
                                    <div class="text-center">
                                    <lee-pie-echarts
                                        :title="nowYear+'年按销售额远置式陈列柜占比'"
                                        ref="pie2"
                                        :grid-bottom="30"
                                        height="350px"
                                        :label-format="'{b}\n{d}%'"
                                        label-pos="outside"
                                        legend-orient="horizontal"
                                        legend-pos="bottom"
                                        :radius="['0%','60%']"></lee-pie-echarts>
                                    </div>
                                </el-col>
                                <el-col :span="12">
                                    <div class="text-center">
                                        <lee-bar-echarts-hor
                                            :title="(nowYear - 4)+'年 - '+nowYear+'年陈列柜产品结构(按销售额)'"
                                            ref="bar5"
                                            :show-right="false"
                                            :show-title="true"
                                            title-pos="top"
                                            :grid-top="30"
                                            height="350px"
                                            :show-legend="true"></lee-bar-echarts-hor>
                                    </div>
                                </el-col>
                            </el-row>
                        </div>
                        <div class="margin-top-50">
                            <lee-bar-echarts-hor-two
                                :title="(nowYear - 1)+'年 - '+ nowYear + '年我国制冷陈列柜各家企业产品类型占比（按销售额）'"
                                :left-title="(nowYear-1)+'年'"
                                :right-title="nowYear+'年'"
                                ref="bar3"
                                :show-right="false"
                                :show-title="true"
                                title-pos="top"
                                :grid-bottom="0"
                                height="550px"
                                :show-legend="true"></lee-bar-echarts-hor-two>
                        </div>
                    </div>
                </div>
            </check-vip>
        </div>
    </lee-page>
</template>

<script>
export default {
    name: "Cabinet",
    data(){
        return{
            yearList:[],
            nowYear:'',

            startYear:'',
            endYear:'',

            data:null,
            bardata:null,
        }
    },
    created() {

        let _this = this;

        let _now = new Date();

        this.$nextTick(()=>{
            // _this.nowYear = _this.$route.query.year ? parseInt(_this.$route.query.year) : (_this.yearList.length > 0 ? _this.yearList[0].value : _now.getFullYear() - 1);
        })
        this.nowYear = this.$route.query.year ? this.$route.query.year : '';

    },
    mounted() {
        this.getYears();
    },
    methods:{

        getYears() {
            let _this = this;

            this.$http.post({
                url: '/HydataZhanshigui/getYears',
                data:null,
                success(res) {
                    for (let i = 0;i < res.length;i++){
                        _this.yearList.push({label:res[i]+'年',value:res[i]});
                    }

                    _this.nowYear = _this.nowYear ? _this.nowYear : res[0];

                    _this.endYear = res[res.length - 1];

                    _this.getTotal1(_this.nowYear);
                    _this.getTotal2(_this.nowYear);
                }
            });
        },

        getTotal1(year){
            let _this = this;
            this.$http.post({
                url:'/HydataZhanshigui/getData',
                data:{year:year,yearlist:[year,year - 4]},
                success(res){
                    let _bardata1 = {bar:[],line:[],xaxis:[]};
                    let _bardata2 = {bar:[],line:[],xaxis:[]};
                    // let _bardata4 = {bar:[],bar2:[],xaxis:[]};

                    let _bardata4 = {bar:[{name:'自携式',type:'bar',color:'green',data:[],stack:'total',dw:'%'},{name:'远置式',type:'bar',color:'blue',data:[],stack:'total',dw:'%'}],xaxis:[]};

                    let _bardata5 = {bar:[{name:'自携式',type:'bar',color:'green',data:[],stack:'total',dw:'%'},{name:'远置式',type:'bar',color:'blue',data:[],stack:'total',dw:'%'}],xaxis:[]};

                    // for(let i=0;i < _list4.length;i++) {
                    //     _bar4.bar.push(_list4[i].total_income);
                    //     _bar4.line.push(_list4[i].income_rate);
                    //     _bar4.xaxis.push(_list4[i].year.toString());
                    //
                    //     _bar5.bar[0].data.push(_list4[i].values[0].val.income);
                    //     _bar5.bar[1].data.push(_list4[i].values[1].val.income);
                    //     _bar5.bar[2].data.push(_list4[i].values[2].val.income);
                    //
                    //     _bar5.xaxis.push(_list4[i].year.toString());
                    // }

                    for (let i = 0; i < res.list.length; i++) {
                        _bardata1.bar.push(res.list[i].total_income);
                        _bardata1.line.push(res.list[i].rate_income);
                        _bardata1.xaxis.push(res.list[i].year ? res.list[i].year : '');

                        _bardata2.bar.push(res.list[i].total_profit);
                        _bardata2.line.push(res.list[i].rate_profit);
                        _bardata2.xaxis.push(res.list[i].year ? res.list[i].year : '');

                        _bardata4.bar[0].data.push(res.list[i].percent_income_zx);
                        _bardata4.bar[1].data.push(res.list[i].percent_income_yz);
                        _bardata4.xaxis.push(res.list[i].year.toString());

                        _bardata5.bar[0].data.push(res.list[i].percent_profit_zx);
                        _bardata5.bar[1].data.push(res.list[i].percent_profit_yz);
                        _bardata5.xaxis.push(res.list[i].year.toString());
                    }

                    if (_this.$refs.bar4) {
                        _this.$refs.bar4.initData(
                            _bardata4.bar,
                            _bardata4.xaxis
                        );
                    }

                    if (_this.$refs.bar5) {
                        _this.$refs.bar5.initData(
                            _bardata5.bar,
                            _bardata5.xaxis
                        );
                    }

                    if (_this.$refs.bar1) {
                        _this.$refs.bar1.initData(
                            [
                                {name: '销量', type: 'bar', color: 'green', data: _bardata1.bar,dw:'万台'},
                                {name: '增长率', type: 'line', color: 'purple', data: _bardata1.line,dw:'%'}
                            ],
                            _bardata1.xaxis
                        );
                    }

                    if (_this.$refs.bar2) {
                        _this.$refs.bar2.initData(
                            [
                                {name: '销售额', type: 'bar', color: 'green', data: _bardata2.bar,dw:'亿元'},
                                {name: '增长率', type: 'line', color: 'purple', data: _bardata2.line,dw:'%'}
                            ],
                            _bardata2.xaxis
                        );
                    }

                    let _pie1Data = [];

                    _pie1Data.push({value:res.now.percent_income_yz,name:'远置式销量占比'});
                    _pie1Data.push({value:res.now.percent_income_zx,name:'自携式销量占比'});

                    if(_this.$refs.pie1) {
                        _this.$refs.pie1.initData(_pie1Data);
                    }

                    let _pie2Data = [];

                    _pie2Data.push({value:res.now.percent_profit_yz,name:'远置式销售额占比'});
                    _pie2Data.push({value:res.now.percent_profit_zx,name:'自携式销售额占比'});

                    if(_this.$refs.pie2) {
                        _this.$refs.pie2.initData(_pie2Data);
                    }
                }
            });
        },

        getTotal2(year) {
            let _this = this;
            this.$http.post({
                url: '/HydataZhanshiguiCompany/getData',
                data: {year: year, yearlist: [year, year - 4]},
                success(res) {
                    console.log(res);

                    let _bardata1 = {bar:[],bar2:[],bar3:[],bar4:[],xaxis:[]};

                    for(let i = 0;i < res.now.length;i++) {
                        _bardata1.bar3.push(res.now[i].percent_profit_zx);
                        _bardata1.bar4.push(res.now[i].percent_profit_yz);
                        _bardata1.xaxis.push(res.now[i].title ? res.now[i].title : '');

                        if(res.last.length <= 0){
                            _bardata1.bar.push(0);
                            _bardata1.bar2.push(0);
                        }
                    }

                    for(let i = 0;i < res.last.length;i++) {
                        _bardata1.bar.push(res.last[i].percent_profit_zx);
                        _bardata1.bar2.push(res.last[i].percent_profit_yz);

                        if(_bardata1.xaxis.length <= 0) {
                            _bardata1.xaxis.push(res.last[i].title ? res.last[i].title : '');
                        }

                        if(res.now.length <= 0){
                            _bardata1.bar3.push(0);
                            _bardata1.bar4.push(0);
                        }
                    }

                    if (_this.$refs.bar3) {
                        _this.$refs.bar3.initData(
                            [
                                {name: '自携式', type: 'bar', color: 'green', data: _bardata1.bar,dw:'亿元',labelPos:'inside',options:{xAxisIndex: 0,yAxisIndex: 0}},
                                {name: '远置式', type: 'bar', color: 'blue', data: _bardata1.bar2,dw:'亿元',labelPos:'inside',options:{xAxisIndex: 0,yAxisIndex: 0}},
                                {name: '自携式', type: 'bar', color: 'green', data:_bardata1.bar3,dw:'亿元',labelPos:'inside',options:{xAxisIndex: 1,yAxisIndex: 1}},
                                {name: '远置式', type: 'bar', color: 'blue', data: _bardata1.bar4,dw:'亿元',labelPos:'inside',options:{xAxisIndex: 1,yAxisIndex: 1}},
                            ],
                            _bardata1.xaxis
                        );
                    }
                }
            })
        }
    }
}
</script>
