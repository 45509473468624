<template>
    <lee-page bg-img="/images/bg_head_3.jpg" :title="title" sub-title="The Top Hundred Corporations" des="" :search="!$isMobile"  :bread="[{title:'研究报告',url:'/yanjiu'},{title:searchForm.type === 'industry' ? '行业发展报告' : '细分领域报告'}]"  :height="526" show-year search-time-key="push_time">
        <div class="bg-img-2 padding-bottom-400 padding-top-50" style="background-color:#f8f9fa">
<!--            <div style="width: 1500px;" class="margin-auto margin-top-100">-->
<!--                <lee-select clearable v-model="year" class="bg-light" :options="searchYearList" style="width: 200px;" @change="changeYear">-->
<!--                </lee-select>-->
<!--            </div>-->
<!--            <div class="cl"></div>-->
<!--          <lee-tabs :list="tabList" :default-index="mm_type" @change="onTabChange" class="margin-auto" style="width: 500px;"></lee-tabs>-->
            <template v-if="list.length > 0">
                <div class="margin-top-20 margin-auto" :style="{width: '1500px'}">
                    <el-row :gutter="10">
                        <el-col :md="8" :xs="24" v-for="(item,index) in list" class="margin-bottom">
                            <div class="bg-light border-radius-5 hand padding-20" style="height:225px" @click="$router.push({path:(searchForm.type === 'domain' ? '/domain/detail/' : '/industry/detail/')+item.id})">
                                <div class="cl"></div>
                                <div class="border-bottom padding-bottom" style="height:60px;line-height: 20px;">
                                    <img src="/images/icon/icon_36.png" width="20" height="20" class="vm"/>
                                    <div class="f18 margin-left-30" style="height:50px;line-height: 20px;margin-top:-20px;" v-html="item.title"></div>
                                </div>
                                <el-row :gutter="10" class="margin-top">
                                    <el-col :md="24" :xs="12" class="margin-bottom" v-if="item.author">
                                        <img src="/images/icon/icon_37.png" width="20" height="20" class="margin-right"/>
                                        <span class="vm dark-3 f16">{{item.author}}</span>
                                    </el-col>
                                    <el-col :md="24" :xs="12" class="margin-bottom">
                                        <img src="/images/icon/icon_38.png" width="20" height="20" class="margin-right"/>
                                        <span class="vm dark-3 f16">{{item.create_time}}</span>
                                    </el-col>
                                    <el-col :md="24" :xs="12" class="margin-bottom" v-if="item.content">
                                        <img src="/images/icon/icon_39.png" width="20" height="20" class="vm"/>
                                        <div class="margin-left-30 text-line-2 vm f16 dark-3" style="height: 40px;line-height: 20px;margin-top: -20px" v-html="item.content"></div>
                                    </el-col>
                                </el-row>
                            </div>
                        </el-col>
                    </el-row>
                </div>
                <div class="text-center margin-top-100">
                    <el-pagination
                        background
                        layout="pager, jumper"
                        :page-size="pageSize"
                        :current-page.sync="searchForm.page"
                        :total="total"
                        @current-change="pageChange">
                    </el-pagination>
                </div>
            </template>
            <el-empty description="暂无数据" v-else></el-empty>
        </div>
    </lee-page>
</template>

<script>
export default {
    name: "Index",
    data(){
        return{
            type:'',
            list:[],
            title:'',
            pageSize:12,
            total:0,
            searchForm:{
                page:1,
                type:'',
                create_time:''
            },
            year:'',
            searchYearList:[],
          mm_type:'chain',
          tabList:[
            {label:'冷链',value:'chain',icon:'/images/icon/icon_57_'},
            {label:'食材供应链',value:'agriculture',icon:'/images/icon/icon_58_'},
            // {label:'冷链/食材供应链',value:'all',icon:'/images/icon/icon_57_'},
          ],
        }
    },
    created() {
        if(!this.$utils.isEmptyObject(this.$route.query)){
            this.searchForm = {...this.$route.query};
        }

        this.searchForm.type = this.$route.params.type;
        this.year = this.$route.query.year ? parseInt(this.$route.query.year) : '';
        this.searchForm.page = this.$route.query.page ? parseInt(this.$route.query.page) : 1;

        if(this.searchForm.type === 'industry'){
            this.title = '行业发展报告';
        }else{
            this.title = '细分领域报告';
        }

        let _nowYear = this.$moment().year();

        for(let i = _nowYear; i > _nowYear - 10;i--){
            this.searchYearList.push({value:i.toString(),label:i.toString()});
        }

    },
    mounted() {
        this.getList();
    },
    methods:{
        // changeYear(e){
        //     if(e) {
        //         let _start = e + '-1-1 00:00:00'; //this.$moment(e+'-1-1 00:00:00').unix();
        //         let _end = e + '-12-31 23:59:59'; //this.$moment(e+'-12-31 23:59:59').unix();
        //
        //         this.searchForm.create_time = [_start, _end];
        //     }else{
        //         this.searchForm.create_time = '';
        //     }
        //
        //     this.$router.replace({query:{...this.searchForm,year:this.year}});
        //     // this.getList();
        //     // console.log(this.$moment(e+'-12-31 23:59:59').unix());
        // },
      onTabChange(i){
        this.mm_type = i.value;
        this.getList();
      },
        getList(){
            let _this = this;

            this.$http.post({
                url:'/Report/listPage',
                data:{...this.searchForm,pageSize:this.pageSize,mm_type:this.mm_type},
                success(res){
                    _this.list = res.list;
                    _this.total = res.totalCount;
                }
            })
        },
        pageChange(page) {
            this.searchForm.page = page;
            this.$router.replace({query:{...this.searchForm,year:this.year}});
        },
    }
}
</script>
