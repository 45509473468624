<template>
    <el-row :gutter="30" class="margin-top-20">
        <el-col :span="12" class="f16 dark-1" v-if="data">
            据中物联冷链委统计，{{nowYear}}年全国冷藏车市场保有量达到{{data.count.now.total}}辆，较上年{{parseInt(data.count.now.total - data.count.last.total) >= 0 ? '增长' : '减少'}}{{parseInt(data.count.now.total - data.count.last.total)}}辆，同比{{data.count.now.rate >= 0 ? '增长' : '减少'}}{{data.count.now.rate}}%
        </el-col>
        <el-col :span="12">
            <lee-bar-echarts
                ref="bar1"
                height="200px"
                width="512px"
                :show-legend="false"
                :show-right="false"
                :bar-width="20"
            ></lee-bar-echarts>
        </el-col>
    </el-row>
</template>

<script>
export default {
    name: "IndexHyData1",
    data(){
        return{
            data:null,
          nowYear:'',
          endYear:''
        }
    },
    props:{
        // year:{
        //     type:[String,Number],
        //     default:''
        // }
    },
    created() {

    },
    mounted() {
        this.getYears();
    },
    methods:{
      getYears() {
        let _this = this;

        this.$http.post({
          url: '/HydataColdCar/getYears',
          data:null,
          success(res) {
            _this.endYear = res[res.length - 1];

            _this.nowYear = res[0];

            _this.getTotal1(_this.nowYear,_this.endYear);
          }
        });
      },
        getTotal1(startYear, endYear){
            let _this = this;
            this.$http.post({
                url:'/HydataColdCar/getData',
                data:{year:startYear},
                success(res){
                    _this.data = res;

                    let _bardata = {bar:[],line:[],xaxis:[]};

                    for(let i=0;i < res.count.list.length;i++) {
                        _bardata.bar.push(res.count.list[i].total);
                        _bardata.line.push(res.count.list[i].rate);
                        _bardata.xaxis.push(res.count.list[i].year.toString());
                    }

                    if(_this.$refs.bar1) {
                        _this.$refs.bar1.initData(
                            [
                                {name: '保有量', type: 'bar', color: 'green', data: _bardata.bar,dw:'辆'},
                                {name: '增长率', type: 'line', color: 'purple', data: _bardata.line,dw:'%'},
                            ],
                            _bardata.xaxis
                        );
                    }
                }
            })
        },
    }
}
</script>
