<template>
    <lee-page bg-img="/images/bg_head_9.jpg" type="light" title-class="margin-top-100" height="280" title="进出口相关数据" :bread="[{title:'行业数据',url:'/hydata'},{title:'进出口'}]">
        <div class="bg-img-2 padding-bottom-400 padding-top-50" :style="{backgroundColor:'#f8f9fa'}">
            <check-vip akey="hangyedata_inout">
                <div class="wrap1100">
                    <lee-year-tab :list="yearList"></lee-year-tab>
                    <div class="box-shadow padding-30 bg-light margin-top-30">
                        <div class="green-line">
                            <div class="cl f34">
                                <lee-img-icon url="/images/icon/icon_31.png" size="30" color="#7ED321" class="z margin-right" v-if="!$isMobile"></lee-img-icon>
                                <div class="z" :class="$isMobile ? 'f18 margin-top dark-2 block' : 'f22'">进出口相关数据</div>
                            </div>
<!--                            <el-row :gutter="20" class="dark-3 margin-top" :class="$isMobile ? 'f12' : 'f14'">-->
<!--                                <el-col :md="5" :xs="12">发布机构：湖北省政府</el-col>-->
<!--                                <el-col :md="5" :xs="12">发布时间：2020-03-26</el-col>-->
<!--                                <el-col :md="5" :xs="12">文号：国药监注[2019]33号</el-col>-->
<!--                            </el-row>-->
                        </div>
                        <div class="f16 dark-2" v-if="data">
                            进出口总体情况如下：
                        </div>
                        <div class="text-center">
                            <lee-bar-echarts
                                ref="hebing"
                                :grid-bottom="$isMobile ? 50 : 30"
                                :height="$isMobile ? '250px' : '350px'"
                                show-legend
                            ></lee-bar-echarts>
                        </div>
                        <div class="f16 dark-2">
                            <div class="green" :class="$isMobile ? 'f20' : 'f30'">进出口额</div>
                            <p v-if="data">
                                {{nowYear}}年，进出⼝总额为{{data.inout.list[data.inout.list.length - 1].total}}亿美⾦，同⽐{{data.inout.list[data.inout.list.length - 1].rate >= 0 ? '增加' : '减少'}}{{ Math.abs(data.inout.list[data.inout.list.length - 1].rate).toFixed(2)}}%。
                            </p>
<!--                            <p v-if="data">我国进出口生鲜农产品市场规模逐步扩大，尤其是从2017年起进口增长迅猛，<template v-for="(item,index) in data.inout.list">-->
<!--                                {{item.year}}年进出口总额为{{item.total}}亿美金，{{item.rate >= 0? '增长' : '减少'}}{{Math.abs(item.rate)}}%，-->
<!--                            </template>如图1-5所示。</p>-->
                            <div class="text-center">
                                <lee-bar-echarts
                                    ref="bar1"
                                    :show-title="true"
                                    :show-left="false"
                                    :bar-width="$isMobile ? 20 : 40"
                                    title-pos="bottom"
                                    :title-size="14"
                                    title="近五年中国跨境农产品进出口额"
                                    :grid-bottom="$isMobile ? 50 : 30"
                                    :height="$isMobile ? '250px' : '350px'"
                                    :show-legend="false"></lee-bar-echarts>
                            </div>
                            <div class="green" :class="$isMobile ? 'f20 margin-top-20' : 'f30'">出口额</div>
                            <p v-if="data">{{nowYear}}年，出⼝总额为{{data.out.now.amount_total.total}}亿美⾦，同⽐{{data.out.now.amount_total.rate >= 0 ? '增加' : '减少'}}{{Math.abs(data.out.now.amount_total.rate).toFixed(2)}}%。</p>
<!--                            <p v-if="data">随着我国经济实力的不断提升，农产品出口量逐步增加，但在中美贸易战及多重因素的影响下，我国跨境农产品的出口仍处于相对稳定状态。-->
<!--                                <template v-for="(item,index) in data.out.list">{{item.year}}年出口{{item.total}}亿美元，</template>，如图1-6所示。</p>-->
                            <div class="text-center">
                                <lee-bar-echarts
                                    ref="bar2"
                                    :show-title="true"
                                    :show-left="false"
                                    :bar-width="$isMobile ? 20 : 40"
                                    title-pos="bottom"
                                    :title-size="14"
                                    title="近五年中国跨境农产品出口额"
                                    :grid-bottom="$isMobile ? 50 : 30"
                                    :height="$isMobile ? '250px' : '350px'"
                                    :show-legend="false"
                                    :show-right="false"
                                    :data="[
                                    {name:'冷链物流业市场规模',type:'bar',color:'green',data:[3740,4200,4750,5238,6052.5]}]"
                                    :x-axis="['2015', '2016', '2017', '2018', '2019']"
                                ></lee-bar-echarts>
                            </div>
                            <p v-if="data">{{nowYear}}年，细分品类出口额为：
                                <template v-for="(item,index) in data.out.now.list">{{item.title}}{{item.amount}}亿人民币，</template>。</p>
                            <div class="text-center margin-top-20">
                                <lee-bar-echarts
                                    ref="bar3"
                                    :title="nowYear+'年中国跨境农产品出口额'"
                                    :show-right="false"
                                    :show-left="false"
                                    :bar-width="$isMobile ? 20 : 40"
                                    :grid-bottom="$isMobile ? 50 : 30"
                                    :height="$isMobile ? '250px' : '350px'"
                                    :show-legend="false"
                                    :data="[
                                    {name:'柴油',type:'bar',color:'most',data:[3740,4200,4750,5238,5238,5238]}]"
                                    :x-axis="['水果', '蔬菜','肉类', '水产品','乳制品', '冷冻食品']"
                                ></lee-bar-echarts>
                            </div>
                            <div class="green" :class="$isMobile ? 'f20' : 'f30'">进口额</div>
                            <p v-if="data">{{nowYear}}年，进⼝总额为{{data.in.now.amount_total.total}}亿美⾦，同⽐{{data.in.now.amount_total.rate >= 0 ? '增加' : '减少'}}{{Math.abs(data.in.now.amount_total.rate).toFixed(2)}}%。</p>
<!--                            <p v-if="data">自全球贸易一体化，我国经济不断地发展，国民生活水平逐步提高，消费者对农产品的需求品类也不断丰富，我国跨境农产品进口额也逐步扩大，<template v-for="(item,index) in data.in.list">{{item.year}}年进口{{item.total}}亿美元，</template> 如图1-8所示。</p>-->
                            <div class="text-center">
                                <lee-bar-echarts
                                    ref="bar4"
                                    :show-title="true"
                                    :show-left="false"
                                    :bar-width="$isMobile ? 20 : 40"
                                    title-pos="bottom"
                                    :title-size="14"
                                    title="近五年中国跨境农产品进口额"
                                    :grid-bottom="$isMobile ? 50 : 30"
                                    :height="$isMobile ? '250px' : '350px'"
                                    :show-right="false"
                                    :show-legend="false"
                                    :data="[
                                    {name:'冷链物流业市场规模',type:'bar',color:'green',data:[3740,4200,4750,5238,6052.5]}]"
                                    :x-axis="['2015', '2016', '2017', '2018', '2019']"
                                ></lee-bar-echarts>
                            </div>
                            <p v-if="data">{{nowYear}}年，细分品类进⼝额为：<template v-for="(item,index) in data.in.now.list">{{item.title}}{{item.amount}}亿人民币，</template>。</p>
                            <div class="text-center margin-top-30">
                                <lee-bar-echarts
                                    ref="bar5"
                                    :show-left="false"
                                    :bar-width="$isMobile ? 20 : 40"
                                    :title="nowYear+'年中国跨境农产品进口额'"
                                    :show-right="false"
                                    :grid-bottom="$isMobile ? 50 : 30"
                                    :height="$isMobile ? '250px' : '350px'"
                                    :show-legend="false"
                                    :data="[
                                    {name:'柴油',type:'bar',color:'most',data:[3740,4200,4750,5238,5238,5238]}]"
                                    :x-axis="['水果', '蔬菜','肉类', '水产品','乳制品', '冷冻食品']"
                                ></lee-bar-echarts>
                            </div>
                        </div>
                    </div>
                </div>
            </check-vip>
        </div>
    </lee-page>
</template>

<script>
export default {
    name: "List",
    data(){
        return{
            yearList:[],
            nowYear:'',

            startYear:'',
            endYear:'',

            data:null,
            yearRange:[]
        }
    },
    created() {
        this.nowYear = this.$route.query.year ? this.$route.query.year : '';
    },
    mounted() {
        this.getYears();
    },
    methods:{
        getYears() {
            let _this = this;

            this.$http.post({
                url: '/HydataInout/getYears',
                data:null,
                success(res) {
                    for (let i = 0;i < res.length;i++){
                        _this.yearList.push({label:res[i]+'年',value:res[i]});
                    }

                    _this.endYear = res[res.length - 1];

                    _this.yearRange = [_this.endYear,res[0]];

                    _this.nowYear = _this.nowYear ? _this.nowYear : res[0];

                    _this.getTotal1(_this.nowYear);
                }
            });
        },

        getTotal1(year){
            //enterprise/statistics
            let _this = this;
            this.$http.post({
                url:'/HydataInout/getData',
                data:{year:year,yearlist:[year,year - 4]},
                success(res){
                    _this.data = res;

                    let _bardata = {bar:[],line:[],xaxis:[]};
                    let _hebing = {bar1:[],bar2:[],line1:[],line2:[],xaxis:[]};

                    for(let i=0;i < res.inout.list.length;i++) {
                        _bardata.bar.push(res.inout.list[i].total);
                        _bardata.line.push(res.inout.list[i].rate);
                        _bardata.xaxis.push(res.inout.list[i].year.toString());
                        _hebing.xaxis.push(res.inout.list[i].year.toString());
                    }

                    if(_this.$refs.bar1) {
                        _this.$refs.bar1.initData(
                            [
                                {name: '进出口总额', type: 'bar', color: 'green', data: _bardata.bar,dw:'亿美元'},
                                {name: '增长率', type: 'line', color: 'purple', data: _bardata.line,dw:'%'},
                            ],
                            _bardata.xaxis
                        );
                    }

                    let _bardata2 = {bar:[],xaxis:[]};

                    for(let i=0;i < res.out.list.length;i++) {
                        _bardata2.bar.push(res.out.list[i].total);
                        _bardata2.xaxis.push(res.out.list[i].year.toString());

                        _hebing.bar2.push(res.out.list[i].total);
                        _hebing.line2.push(res.out.list[i].rate);
                    }

                    if(_this.$refs.bar2) {
                        _this.$refs.bar2.initData(
                            [
                                {name: '出口总额', type: 'bar', color: 'green', data: _bardata2.bar,dw:'亿美元'},
                            ],
                            _bardata2.xaxis
                        );
                    }

                    let _bardata3 = {bar:[],xaxis:[]};

                    for(let i=0;i < res.out.now.list.length;i++) {
                        _bardata3.bar.push(res.out.now.list[i].amount);
                        _bardata3.xaxis.push(res.out.now.list[i].title);
                    }

                    if(_this.$refs.bar3) {
                        _this.$refs.bar3.initData(
                            [
                                {name: '农产品出口总额', type: 'bar', color: 'most', data: _bardata3.bar,dw:'亿美元'},
                            ],
                            _bardata3.xaxis
                        );
                    }

                    let _bardata4 = {bar:[],xaxis:[]};

                    for(let i=0;i < res.in.list.length;i++) {
                        _bardata4.bar.push(res.in.list[i].total);
                        _bardata4.xaxis.push(res.in.list[i].year.toString());

                        _hebing.bar1.push(res.in.list[i].total);
                        _hebing.line1.push(res.in.list[i].rate);
                    }

                    if(_this.$refs.bar4) {
                        _this.$refs.bar4.initData(
                            [
                                {name: '进口总额', type: 'bar', color: 'green', data: _bardata4.bar,dw:'亿美元'},
                            ],
                            _bardata4.xaxis
                        );
                    }

                    let _bardata5 = {bar:[],xaxis:[]};

                    for(let i=0;i < res.in.now.list.length;i++) {
                        _bardata5.bar.push(res.in.now.list[i].amount);
                        _bardata5.xaxis.push(res.in.now.list[i].title);
                    }

                    if(_this.$refs.bar5) {
                        _this.$refs.bar5.initData(
                            [
                                {name: '农产品进口总额', type: 'bar', color: 'most', data: _bardata5.bar,dw:'亿美元'},
                            ],
                            _bardata5.xaxis
                        );
                    }

                    if(_this.$refs.hebing) {
                        _this.$refs.hebing.initData(
                            [
                                {name: '进口总额', type: 'bar', color: 'green', data: _hebing.bar1,dw:'亿美元'},
                                {name: '出口总额', type: 'bar', color: 'blue', data: _hebing.bar2,dw:'亿美元'},
                                {name: '进口额增长率', type: 'line', color: 'purple', data: _hebing.line1,dw:'%'},
                                {name: '出口增长率', type: 'line', color: 'red', data: _hebing.line2,dw:'%'},
                            ],
                            _hebing.xaxis
                        );
                    }
                }
            })
        },
    }
}
</script>
