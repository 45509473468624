<template>
    <lee-page bg-img="/images/bg_head_9.jpg" type="light" title-class="margin-top-100" height="280" title="冷库相关数据" :bread="[{title:'行业数据',url:'/hydata'},{title:'冷库'}]">
        <div class="bg-img-2 padding-bottom-400 padding-top-50" :style="{backgroundColor:'#f8f9fa'}">
            <check-vip akey="hangyedata_storage">
                <div class="wrap1100">
                    <lee-year-tab :list="yearList"></lee-year-tab>
                    <div class="box-shadow padding-30 bg-light margin-top-30">
                        <div class="green-line">
                            <div class="cl f34">
                                <lee-img-icon url="/images/icon/icon_31.png" size="30" color="#7ED321" class="z margin-right" v-if="!$isMobile"></lee-img-icon>
                                <div class="z" :class="$isMobile ? 'f18 margin-top dark-2 block' : 'f22'">冷库相关数据</div>
                            </div>
<!--                            <el-row :gutter="20" class="dark-3 margin-top" :class="$isMobile ? 'f12' : 'f14'">-->
<!--                                <el-col :md="5" :xs="12">发布机构：湖北省政府</el-col>-->
<!--                                <el-col :md="5" :xs="12">发布时间：2020-03-26</el-col>-->
<!--                                <el-col :md="5" :xs="12">文号：国药监注[2019]33号</el-col>-->
<!--                            </el-row>-->
                        </div>
<!--                        <div class="f16 dark-2">-->
<!--                            <p>重点企业冷库总容积：</p>-->
<!--                            <div class="text-center">-->
<!--                                <lee-bar-echarts-->
<!--                                    ref="bar5"-->
<!--                                    :grid-bottom="30"-->
<!--                                    height="350px"-->
<!--                                    show-legend-->
<!--                                ></lee-bar-echarts>-->
<!--                            </div>-->
<!--                        </div>-->
                        <div class="f16 dark-2 margin-top-30" v-if="data">
                            冷库总体情况如下：
                        </div>
                        <div class="text-center">
                            <lee-bar-echarts
                                ref="hebing"
                                :grid-bottom="$isMobile ? 50 : 30"
                                :height="$isMobile ? '250px' : '350px'"
                                show-legend
                            ></lee-bar-echarts>
                        </div>
                        <div class="f16 dark-2">
                            <p v-if="data">{{nowYear}}年全国冷库容量达到{{data.size.now.total}}万吨（折合约{{data.volume.now.total}}万立方米）<template v-if="data.size.last">，{{(parseFloat(data.size.now.total) - parseFloat(data.size.last.total)) >= 0 ? '新增' : '减少'}}库容{{ (Math.abs(parseFloat(data.size.now.total) - parseFloat(data.size.last.total))).toFixed(2)}}万吨，同比{{parseFloat(data.size.now.rate) >= 0 ? '增长' : '减少'}}{{Math.abs(data.size.now.rate).toFixed(2)}}%</template>。
                            </p>
                            <div class="text-center">
                                <el-row :gutter="10">
                                    <el-col :md="12" :xs="24">
                                        <lee-bar-echarts
                                            ref="bar1"
                                            :grid-bottom="$isMobile ? 50 : 30"
                                            :height="$isMobile ? '250px' : '350px'"
                                            show-legend
                                        ></lee-bar-echarts>
                                    </el-col>
                                    <el-col :md="12" :xs="24">
                                        <lee-bar-echarts
                                            ref="bar2"
                                            :grid-bottom="$isMobile ? 50 : 30"
                                            :height="$isMobile ? '300px' : '350px'"
                                            show-legend
                                        ></lee-bar-echarts>
                                    </el-col>
                                </el-row>
                            </div>
                            <p v-if="data">{{nowYear}}年区域冷库分布情况如下，<template v-for="(item,index) in data.volumelist">{{item.title}}{{item.volume}}万立方米{{index < data.volumelist.length - 1 ? '、' : ''}}</template></p>
                            <div>
                                <lee-pie-echarts
                                    ref="pie1"
                                    :grid-bottom="$isMobile ? 150 : 30"
                                    :height="$isMobile ? '350px' : '350px'"
                                    :label-format="'{b}\n{d}%'"
                                    label-pos="outside"
                                    :radius="['0%','60%']"></lee-pie-echarts>
                            </div>
<!--                            <p v-if="data">{{nowYear}}年区域冷库分布情况如下，<template v-for="(item,index) in data.volumelist">{{item.title}}{{item.volume}}立方米</template> {{data.volumelist[0].title}}{{data.volumelist[0].volume}}立方米、其次{{data.volumelist[1].title}}{{data.volumelist[1].volume}}立方米、再次{{data.volumelist[2].title}}{{data.volumelist[2].volume}}立方米，后续依次为{{data.volumelist[3].title}}{{data.volumelist[3].volume}}立方米、{{data.volumelist[4].title}}{{data.volumelist[4].volume}}立方米、{{data.volumelist[5].title}}{{data.volumelist[5].volume}}立方米、{{data.volumelist[6].title}}{{data.volumelist[6].volume}}立方米。</p>-->
                        </div>
                    </div>
                </div>
            </check-vip>
        </div>
    </lee-page>
</template>

<script>
export default {
    name: "List",
    data(){
        return{
            yearList:[],
            nowYear:'',

            startYear:'',
            endYear:'',

            data:null,
        }
    },
    created() {
        let _this = this;

        let _now = new Date();

        this.$nextTick(()=>{
            // _this.nowYear = _this.$route.query.year ? parseInt(_this.$route.query.year) : (_this.yearList.length > 0 ? _this.yearList[0].value : _now.getFullYear() - 1);
        })
        this.nowYear = this.$route.query.year ? this.$route.query.year : '';
        // this.nowYear = this.$route.query.year ? this.$route.query.year : this.yearList[0].value;
    },
    mounted() {
        this.getYears();
    },
    methods:{
        getYears() {
            let _this = this;

            this.$http.post({
                url: '/HydataLengku/getYears',
                data:null,
                success(res) {
                    for (let i = 0;i < res.length;i++){
                        _this.yearList.push({label:res[i]+'年',value:res[i]});
                    }

                    _this.nowYear = _this.nowYear ? _this.nowYear : res[0];
                    _this.endYear = res[res.length - 1];

                    _this.getTotal1(_this.nowYear);
                }
            });
        },

        getTotal1(year){
            //enterprise/statistics
            let _this = this;
            this.$http.post({
                url:'/HydataLengku/getData',
                data:{year:year,yearlist:[year,year - 4]},
                success(res){
                    _this.data = res;

                    let _bardata = {bar:[],line:[],xaxis:[]};

                    let _hebing = {bar1:[],bar2:[],bar3:[],xaxis:[]};

                    for(let i=0;i < res.size.list.length;i++) {
                        _bardata.bar.push(res.size.list[i].total);
                        _bardata.line.push(res.size.list[i].rate);
                        _bardata.xaxis.push(res.size.list[i].year.toString());

                        _hebing.bar1.push(res.size.list[i].total);
                        _hebing.xaxis.push(res.size.list[i].year.toString());
                    }

                    if(_this.$refs.bar1) {
                        _this.$refs.bar1.initData(
                            [
                                {name: '冷库容量', type: 'bar', color: 'green', data: _bardata.bar,dw:'万吨'},
                                {name: '增长率', type: 'line', color: 'purple', data: _bardata.line,dw:'%'},
                            ],
                            _bardata.xaxis
                        );
                    }

                    let _bar2data = {bar:[],line:[],xaxis:[]};

                    for(let i=0;i < res.volume.list.length;i++) {
                        _bar2data.bar.push(res.volume.list[i].total);
                        _bar2data.line.push(res.volume.list[i].rate);
                        _bar2data.xaxis.push(res.volume.list[i].year.toString());

                        _hebing.bar2.push(res.volume.list[i].total);
                    }

                    if(_this.$refs.bar2) {
                        _this.$refs.bar2.initData(
                            [
                                {name: '冷库容积', type: 'bar', color: 'green', data: _bar2data.bar,dw:'万立方米'},
                                {name: '增长率', type: 'line', color: 'purple', data: _bar2data.line,dw:'%'},
                            ],
                            _bar2data.xaxis
                        );
                    }

                    let _pie1data = [];
                    for(let i=0;i < res.arealist.length;i++) {
                        _pie1data.push({value:res.arealist[i].volume,name:res.arealist[i].title});
                    }

                    for(let i=0;i < res.area.list.length;i++) {
                        _hebing.bar3.push(res.area.list[i].total);
                    }

                    if(_this.$refs.pie1) {
                        _this.$refs.pie1.initData(_pie1data);
                    }

                    if(_this.$refs.hebing) {
                        _this.$refs.hebing.initData(
                            [
                                {name: '容量', type: 'bar', color: 'green', data: _hebing.bar1,dw:'万吨'},
                                {name: '容积', type: 'bar', color: 'blue', data: _hebing.bar2,dw:'万立方米'},
                                {name: '面积', type: 'bar', color: 'orange', data: _hebing.bar3,dw:'平方米'},
                            ],
                            _hebing.xaxis
                        );
                    }

                    let _bar5data = {bar:[],xaxis:[]};

                    for(let i=0;i < res.baiqiang_lengku.list.length;i++) {
                        _bar5data.bar.push(res.baiqiang_lengku.list[i].lengku_total);
                        _bar5data.xaxis.push(res.baiqiang_lengku.list[i].year.toString());
                    }

                    if(_this.$refs.bar5) {
                        _this.$refs.bar5.initData(
                            [
                                {name: '重点企业冷库总容积', type: 'bar', color: 'green', data: _bar5data.bar,dw:'万立方米'},
                            ],
                            _bar5data.xaxis
                        );
                    }
                }
            })
        },
    }
}
</script>
