<template>
    <lee-page bg-img="/images/bg_head_9.jpg" type="light" title-class="margin-top-100" height="280" title="各地特色农产品" :bread="[{title:'行业数据',url:'/hydata'},{title:'各地特殊农产品'}]">
        <div class="bg-img-2 padding-bottom-400 padding-top-50" :style="{backgroundColor:'#f8f9fa'}">
            <check-vip akey="hangyedata_characteristic">
                <div class="wrap1100">
                    <lee-year-tab :list="yearList"></lee-year-tab>
                    <div class="box-shadow padding-30 bg-light margin-top-30" v-if="yearList.length > 0">
                        <div class="green-line">
                            <div class="cl f34">
                                <lee-img-icon url="/images/icon/icon_31.png" size="30" color="#7ED321" class="z margin-right"></lee-img-icon>
                                <div class="z f22">各地特色农产品</div>
                            </div>
<!--                            <el-row :gutter="20" class="dark-3 margin-top f14">-->
<!--                                <el-col :md="5" :xs="12">发布机构：湖北省政府</el-col>-->
<!--                                <el-col :md="5" :xs="12">发布时间：2020-03-26</el-col>-->
<!--                                <el-col :md="5" :xs="12">文号：国药监注[2019]33号</el-col>-->
<!--                            </el-row>-->
                        </div>
                        <div class="f16 dark-2">
                            <lee-china-echarts height="600px" @click="click" @onload="onload"></lee-china-echarts>
                            <table class="lee-table margin-top-30 margin-auto" style="width: 100%;">
                                <thead>
                                <td colspan="5">{{province_name}}地标({{data.length}}个)</td>
                                </thead>
                                <tr class="title">
                                    <td>产品名称</td>
                                    <td>产地</td>
                                    <td>产品编号</td>
                                    <td>证书持有者</td>
                                    <td>登记年份</td>
                                </tr>
                                <tr v-for="(item,index) in data" v-if="data.length > 0">
                                    <td>{{item.title}}</td>
                                    <td>{{item.place}}</td>
                                    <td>{{item.product_no}}</td>
                                    <td>{{item.keeper}}</td>
                                    <td>{{item.year}}</td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            </check-vip>
        </div>
    </lee-page>
</template>

<script>
export default {
    name: "List",
    data(){
        return{
            yearList:[],
            nowYear:'',

            startYear:'',
            endYear:'',

            province_code:'',
            province_name:'',
            yearRange:[],

            data:[],
        }
    },
    created() {
        let _this = this;

        let _now = new Date();

        this.$nextTick(()=>{
            // _this.nowYear = _this.$route.query.year ? parseInt(_this.$route.query.year) : (_this.yearList.length > 0 ? _this.yearList[0].value : _now.getFullYear() - 1);
        })
        this.nowYear = this.$route.query.year ? this.$route.query.year : '';
    },
    mounted() {
        this.getYears();
    },
    methods: {
        getYears() {
            let _this = this;

            this.$http.post({
                url: '/HydataProduct/getYears',
                data:null,
                success(res) {
                    for (let i = 0;i < res.length;i++){
                        _this.yearList.push({label:res[i]+'年',value:res[i]});
                    }

                    _this.endYear = res[res.length - 1];

                    _this.nowYear = _this.nowYear ? _this.nowYear : res[0];

                    // _this.getTotal1(_this.nowYear);
                }
            });
        },

        getTotal1(year) {
            //enterprise/statistics
            let _this = this;
            this.$http.post({
                url: '/HydataProduct/listAll',
                data: {year: year, province_code: this.province_code},
                success(res) {
                    _this.data = res;
                }
            })
        },

        click(e){
            // console.log(e);
            this.province_code = e.province_code;
            this.province_name = e.province_name;

            // this.$router.replace({query:{code:e.province_code}});
            this.nowYear = this.nowYear ? this.nowYear : this.yearList[0].value;

            this.getTotal1(this.nowYear);
        },

        onload(e){
            // console.log(e);
            this.province_code = e.province_code;
            this.province_name = e.province_name;
            // this.$router.replace({query:{code:e.province_code}});

            this.nowYear = this.nowYear ? this.nowYear : this.yearList[0].value;
            this.getTotal1(this.nowYear);
        }
    }
}
</script>
