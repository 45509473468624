<template>
    <lee-page bg-img="/images/bg_head_2.jpg" title="政策&nbsp;&nbsp;标准" sub-title="Policies & Standards" des="" search stop-search to-path="/zhengce/search" search-time-key="push_time" show-policy show-year>
        <template v-if="$isMobile">
            <div class="margin-top padding-left-right">
                <div class="border-radius-5 margin-bottom qa-list">
                    <div class="bg-green box-shadow title cl bg-light padding" style="height: 80px;line-height: 60px;" @click="showZhengce = !showZhengce">
                        <div class="z margin-left f20 light" style="line-height: 30px;">
                            <div class="f-lq f26">政策</div>
                            <div class="f-thin">The Policy</div>
                        </div>
                        <span class="y bg-light text-center border-radius" style="height: 30px;width: 30px;line-height: 30px !important;margin-top:15px;">
                            <i class="green" :class="showZhengce ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"></i>
                        </span>
                    </div>
                    <div class="margin-top" :class="showZhengce ? '' : 'none'">
                        <el-row :gutter="10">
                            <el-col class="margin-bottom" :span="12" v-for="(item,index) in zcList">
                                <div style="height: 80px;line-height: 60px;" class="border-radius-5 bg-light padding box-shadow f16 dark-2" @click="$router.push({path:item.path})">
                                    <lee-img-icon :url="item.icon" class="margin-right vm bg-green-line box-shadow-green" size="36"/>
                                    {{item.title}}
                                </div>
                            </el-col>
                        </el-row>
                    </div>
                </div>
                <div class="border-radius-5 margin-bottom qa-list">
                    <div class="bg-blue box-shadow title cl bg-light padding" style="height: 80px;line-height: 60px;" @click="showBiaozhun = !showBiaozhun">
                        <div class="z margin-left f20 light" style="line-height: 30px;">
                            <div class="f-lq f26">标准</div>
                            <div class="f-thin">The Standard</div>
                        </div>
                        <span class="y bg-light text-center border-radius" style="height: 30px;width: 30px;line-height: 30px !important;margin-top:15px;">
                            <i class="green" :class="showBiaozhun ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"></i>
                        </span>
                    </div>
                    <div class="margin-top" :class="showBiaozhun ? '' : 'none'">
                        <el-row :gutter="10">
                            <el-col class="margin-bottom" :span="12" v-for="(item,index) in bzList">
                                <div style="height: 80px;line-height: 60px;" class="border-radius-5 bg-light padding box-shadow f16 dark-2" @click="$router.push({path:item.path})">
                                    <lee-img-icon :url="item.icon" class="margin-right vm bg-blue" size="36"/>
                                    {{item.title}}
                                </div>
                            </el-col>
                        </el-row>
                    </div>
                </div>
            </div>
        </template>
        <template v-else>
            <div style="width: 900px;margin-top:-45px;height: 90px;" class="margin-auto">
                <el-row :gutter="20">
                    <el-col :span="12">
                        <div class="bg-light padding-30 f-thin f16 box-shadow" style="height: 90px;line-height: 30px;">
                            <img src="/images/icon/icon_16.png" class="z" height="32"/>
                            <b class="z f-thin dark-2 margin-left-30">新增政策</b>
                            <i class="z green f42 f-b margin-left-30">{{policyCount}}+</i>
                        </div>
                    </el-col>
                    <el-col :span="12">
                        <div class="bg-light padding-30 f-thin f16 box-shadow" style="height: 90px;line-height: 30px;">
                            <img src="/images/icon/icon_17.png" class="z" height="32"/>
                            <b class="z f-thin dark-2 margin-left-30">新增标准</b>
                            <i class="z green f42 f-b margin-left-30">{{standardCount}}+</i>
                        </div>
                    </el-col>
                </el-row>
            </div>
            <div class="bg-img-5 text-center">
                <span class="rel inline-block">
                    <img src="/images/img-map1.png" height="980"/>
<!--                    <lee-map-hot size="128" top="217" left="434" @click.native="$router.push({path:'/zhengce/list/standard/'+bzList[0].id})"></lee-map-hot>-->
                    <lee-map-hot size="131" top="137" right="284" @click.native="$router.push({path:'/zhengce/list/standard/'+bzList[1].id})"></lee-map-hot>
                    <lee-map-hot size="169" top="322" right="79" @click.native="$router.push({path:'/zhengce/list/standard/'+bzList[2].id})"></lee-map-hot>
                    <lee-map-hot size="130" top="550" right="206" @click.native="$router.push({path:'/zhengce/list/standard/'+bzList[3].id})"></lee-map-hot>
                    <lee-map-hot size="130" top="301" left="206" @click.native="$router.push({path:'/zhengce/list/policy/'+zcList[0].id})"></lee-map-hot>
                    <lee-map-hot size="168" top="490" left="80" @click.native="$router.push({path:'/zhengce/list/policy/'+zcList[1].id})"></lee-map-hot>
                    <lee-map-hot size="130" bottom="138" left="284" @click.native="$router.push({path:'/zhengce/list/policy/'+zcList[2].id})"></lee-map-hot>
                    <lee-map-hot size="128" bottom="216" left="554" @click.native="$router.push({path:'/zhengce/list/policy/'+zcList[3].id})"></lee-map-hot>
                </span>
            </div>
            <div class="bg-img-7 padding-bottom-400">
                <div class="cl"></div>
                <div class="text-center f30 light margin-top-50">最近更新</div>
                <div class="wrap1100 margin-top-60">
                    <lee-swiper>
                        <el-carousel-item v-for="(item,index) in newList" :key="'new_list_'+item.id">
                            <div class="bg-light" @click="$router.push({path:item.type+'/detail/'+item.id})">
                                <div style="height:50px;line-height:50px;background: linear-gradient(134deg,#7ed321, #5a9717 100%);padding:0 20px;" class="light cl">
                                  <div class="z">
                                    <span class="f36" style="vertical-align: middle">{{item.issued_date2}}</span>
                                    <span style="vertical-align: middle" class="margin-left-20">{{item.issued_date1}}</span>
                                  </div>
                                  <div class="y">{{item.type ==='policy' ? '政策' : '标准'}}/{{item.policy.name}}</div>
                                </div>
                                <div class="padding-20">
                                    <div class="f20 f-b">{{item.title}}</div>
                                    <div class="f16 margin-top f-thin text-line-3" v-html="item.content"></div>
                                </div>
                            </div>
                        </el-carousel-item>
                    </lee-swiper>
                </div>
            </div>
        </template>
    </lee-page>
</template>

<script>
export default {
    name: "Index",
    data(){
        return{
            showZhengce:true,
            showBiaozhun:true,
            zcList:[],
            bzList:[],
            policyCount:0,
            standardCount:0,
            newList:[]
        }
    },
    mounted() {
        this.getTotal2();
        this.getTotal3();
        this.getStandardList();
        this.getPolicyList();
        this.getPolicyType();
        this.getStandardType();
    },
    methods:{
        getPolicyType(){
            let _this = this;
            this.$http.post({
                url:'/Type/listAll',
                data:{akey:'policy',orderBy:'sort',sortBy:'asc'},
                success(res){
                    _this.zcList = res;
                }
            })
        },
        getStandardType(){
            let _this = this;
            this.$http.post({
                url:'/Type/listAll',
                data:{akey:'standard',orderBy:'sort',sortBy:'asc'},
                success(res){
                    _this.bzList = res;
                }
            })
        },
        getTotal2(){
            let _this = this;
            this.$http.post({
                url:'/Policy/count',
                data:{type:'policy'},
                success(res){
                    _this.policyCount = res.count;
                }
            })
        },
        getTotal3(){
            //statistical/getCount
            let _this = this;
            this.$http.post({
                url:'/Policy/count',
                data:{type:'standard'},
                success(res){
                    _this.standardCount = res.count;
                }
            })
        },
        getStandardList(){
            let _this = this;
            this.$http.post({
                url:'/Policy/listPage',
                data:{page:1,pageSize:3,type:'standard',user_vip_type:[['free','user'],'in']},
                success(res){
                    for(let i=0;i<res.list.length;i++){
                        let _issued_date = res.list[i].create_time;

                        let _time = _issued_date.split(' ');

                        let _time2 = _time[0].split('-');

                        _this.newList.push({...res.list[i],slug:'standard',issued_date1:_time2[0]+'-'+_time2[1],issued_date2:_time2[2]});
                    }
                }
            })
        },
        getPolicyList(){
            let _this = this;
            this.$http.post({
                url:'/Policy/listPage',
                data:{page:1,pageSize:3,type:'policy',user_vip_type:[['free','user'],'in']},
                success(res){
                    for(let i=0;i<res.list.length;i++){
                        let _issued_date = res.list[i].create_time;

                        let _time = _issued_date.split(' ');

                        let _time2 = _time[0].split('-');

                        _this.newList.push({...res.list[i],slug:'policy',issued_date1:_time2[0]+'-'+_time2[1],issued_date2:_time2[2]});
                    }
                }
            })
        }
    }
}
</script>
