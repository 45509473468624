<template>
	<div :id="id" :style="{height:height,width:width}"></div>
</template>

<script>
export default {
	name: "NewPidEcharts",
	data() {
		return {
			id: 'echarts',
			datas:[],
			selectIndex:0,
			timer:null,
			maxIndex:0,
			showTitles:true,
			option: {
				title: {
					text: '',
					left: '50%',
					top: '50%',
					show: true,
					textStyle: {
						fontWeight: 'normal',
						fontSize: 18,
						color:'#fff'
					},
					subtext: '',
					subtextStyle: {
						fontWeight: 'bold',
						color: '#666',
						align:'center',
						fontSize: 14,
					},
					textAlign:'center',
				},
				tooltip: {
					show:false,
					trigger: 'item',
					// formatter: '{b} : {c} ({d}%)'
				},
				grid: {
					top: 20,
					containLabel: true
				},
				legend: null,
				// color: ["#7ED321", "#5C87F6", "#FBC531", "#E74218", "#02C5C6", "#9980F9", "#E056FD"],
				color:['#1054a7','#30c25a','#1990ff','#fe4f6e','#488cf7','#8642e0','#39cafb','#fbcb15','#4eaffa','#16c2c1','#f67c37','#4adfcb'],
				series: [
					{
						roseType: 'area',
						// avoidLabelOverlap: false,
						name: '访问来源',
						type: 'pie',
						radius: ['0%', '70%'],
						data: [],
						padAngle:0,
						silent:true,
						emphasis: {
							scaleSize:20,
							label: {
								padding:[16,0,0,0],
								show: true,
								color:'inherit',
								fontSize: 50,
								fontWeight: 'bold',
								formatter:[
									'{b|{b}}',
									'{d|{d}}'
								].join('\n'),
								rich: {
									b: {
										fontSize:14,
										// lineHeight: 10
									},
									d:{
										fontWeight:'bold',
										fontSize:24,
										lineHeight:40
									}
								}
							}
						},
						label: {
							show: false,
							formatter: '{c}{d}%',
							position: 'center',
							fontSize: 16,
							borderWidth:0,
							color:'#fff'
							// alignTo:'labelLine'
						},
						// itemStyle: {
							// borderRadius: 10,
							// borderColor: '#fff',
							// borderWidth: 2
						// },
					}
				]
			}
		}
	},
	props: {
		width: String,
		height: String,
		title: [String,Number],
		subTitle: [String,Number],
		titleColor: {
			type:String,
			default:'#fff'
		},
		subTitleColor:  {
			type:String,
			default:'#fff'
		},
		radius: {
			type: Array,
			default() {
				return ['0%', '70%']
			}
		},
		showLegend: {
			type: Boolean,
			default: true
		},
		legendOrient: {
			type: String,
			default: 'horizontal'
		},
		labelPos: {
			type: String,
			default: 'outside'
		},
		labelFormat: {
			type: String,
			default: '{d}%'
		},
		titleTop: {
			type: [String,Number],
			default: ''
		},
		titleLeft: {
			type: [String,Number],
			default: ''
		},
		titleSize: {
			type: Number,
			default: 18
		},
		subTitlePos: {
			type: String,
			default: 'top'
		},
		roseType: {
			type: [String,Boolean],
			default: 'radius'
		},
		subTitleSize: {
			type: Number,
			default: 18
		},
		labelSize: {
			type: Number,
			default: 14
		},
		padAngle: {
			type: Number,
			default: 0
		},
		showTitle: {
			type: Boolean,
			default: true
		},
		showLabel: {
			type: Boolean,
			default: true
		},
		data: {
			type: Array,
			default() {
				return []
			}
		},
		legendPos: {
			type: String,
			default: 'bottom'
		},
		gridBottom: {
			type: Number,
			default: 30
		}
	},
	created() {
		// this.$echarts.set

		this.id = this.$utils.randomWords(8);

		this.option.title.top = this.titleTop;
		this.option.title.left = this.titleLeft;

		this.option.series[0].label.formatter = this.labelFormat;
		this.option.series[0].radius = this.radius;
		// this.option.series[0].label.position = this.labelPos;
		// this.option.series[0].label.show = this.showLabel;
		this.option.series[0].roseType = this.roseType;
		this.option.series[0].padAngle = this.padAngle;

		this.option.title.textStyle.fontSize = this.titleSize;
		this.option.title.subtextStyle.fontSize = this.subTitleSize;

		this.option.grid.bottom = this.gridBottom;

		if (this.title) {
			this.option.title.text = this.title;
			this.option.title.textStyle.color = this.titleColor;
			// this.option.grid.top = 40;
		}

		if (this.subTitle) {
			this.option.title.subtext = this.subTitle;
			this.option.title.subtextStyle.color = this.subTitleColor;
		}

		this.option.title.show = this.showTitles;
		this.option.series[0].label.fontSize = this.labelSize
		this.option.series[0].data = this.data;

		// this.option.tooltip.formatter = (params)=>{
		//   let relVal = params[0].name;
		//   for (let i = 0, l = params.length; i < l; i++) {
		//     relVal += '<div style="display:flex;flex-direction: row;align-items: center;justify-content: space-between;"><span style="flex:1;margin-right:10px;">' + params[i].marker + (params[i].seriesName ? params[i].seriesName : params[i].name) + ' : </span><span style="flex:1;text-align: right;">' + params[i].value + '</span>'+ (data[i] && data[i].dw ? data[i].dw : '') +'</div>'
		//   }
		//   return relVal;
		// }

	},
	mounted() {
		// console.log('===mounted====')
		this.echarts = this.$echarts.init(document.getElementById(this.id));
		this.echarts.setOption(this.option, true);
		window.addEventListener("resize", this.resize);
	},
	beforeDestroy() {
		window.removeEventListener("resize", this.resize);
		if(this.timer){
			clearInterval(this.timer);
			this.timer = null;
		}
	},
	methods: {
		resize(){
			this.echarts.resize();
		},
		autoRun(){
			if(this.datas.length > 0){
				if(this.selectIndex >= this.datas.length){
					this.selectIndex = 0;
				}
				// console.log(this.selectIndex);
				// this.timer = setInterval(()=>{
					this.echarts.dispatchAction({type: 'downplay',seriesIndex: 0,dataIndex: [0,1,2,3,4,5,6,7,8]});
					this.echarts.dispatchAction({type: 'highlight',seriesIndex: 0,dataIndex: this.selectIndex});
					this.selectIndex += 1;
				// },2000)
			}
		},
		initData(data, dw) {
			// console.log(data);
			if (data.length > 0) {
				this.datas = data;
				this.maxIndex = 0
				let _max = 0;
				let _legend = [];
				let _all_index = [];
				for (let i = 0; i < data.length; i++) {
					let _data = data[i];
					_all_index.push(i);

					if(_data.value > _max){
						_max = _data.value;
						this.maxIndex = i;
					}
					_legend.push(_data.name);
				}

				// console.log(this.maxIndex);

				if (this.showLegend) {
					this.option.legend = this.$echartsJs.getLegend(_legend, this.legendPos, this.legendOrient);
				}

				this.option.series[0].data = data;

				this.option.tooltip.formatter = '{b} : {c} ' + (dw ? dw : '') + ' ({d}%)'

				// console.log(this.option.title.show);

				this.echarts.setOption(this.option, true);

				this.timer = setInterval(this.autoRun,2000)

				this.echarts.dispatchAction({type: 'highlight',seriesIndex: 0,dataIndex: 0});

				// this.echarts.on('mouseover', (v) => {
				// 	this.showTitles = false;
				// 	this.option.title.show = false;
				// 	this.echarts.setOption({
				// 		title: {
				// 			show: false
				// 		}
				// 	})
				// });
				//
				// this.echarts.on('mouseout',(v) => {
				// 	this.showTitles = true;
				// 	this.option.title.show = true;
				// 	this.echarts.setOption({
				// 		title: {
				// 			show: true
				// 		}
				// 	})
				// 	console.log(this.option.title.show);
				// })

				// this.echarts.on('mouseover', { seriesName: 'from' }, params => {
				// 	console.log('====')
				// 	this.echarts.setOption({
				// 		title: {
				// 			show: false
				// 		}
				// 	})
				// })
				//
			}
		}
	}
}
</script>
