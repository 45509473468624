<template>
	<div style="padding:20px;">
		<el-row :gutter="10">
			<el-col :span="9">
				<el-card header="柱状图">
					<lee-bar-echarts-2
						:show-zoom="false"
						ref="bar1"
						height="300px"
						width="100%"
						:show-legend="false"
						:show-right="true"
						:show-y-split-line="false"
						:bar-width="40"
						:x-name-rotate="0"
					></lee-bar-echarts-2>
				</el-card>
			</el-col>
			<el-col :span="9">
				<el-card header="折线图">
					<lee-bar-echarts-2
						:show-zoom="false"
						:grid-top="40"
						ref="bar2"
						height="300px"
						width="100%"
						:show-legend="false"
						:show-right="true"
						:show-y-split-line="false"
						:bar-width="40"
						:x-name-rotate="0"
					></lee-bar-echarts-2>
				</el-card>
			</el-col>
			<el-col :span="6">
				<el-card header="饼状图">
					<lee-new-pie-echarts-2
						ref="pie1"
						title=""
						sub-title=""
						sub-title-color="#000"
						title-color="#000"
						:title-size="14"
						:sub-title-size="24"
						:grid-bottom="30"
						height="300px"
						width="100%"
						:rose-type="false"
						:label-format="'{b}\n{d}%'"
						title-top="40%"
						title-left="49%"
						:show-legend="false"
						legend-orient="vertical"
						legend-pos="right"
						:radius="['60%','80%']"></lee-new-pie-echarts-2>
				</el-card>
			</el-col>
		</el-row>
	</div>
</template>

<script>
export default {
	name: "index",
	data() {
		return {}
	},
	created() {
	},
	mounted() {
		this.init();
	},
	methods: {
		init(){
			let _bardata = {bar:[100,200,300,400,1000,200,500,300],line:[0,23,58.3,39,13,66,44,29],xaxis:['1月','2月','3月','4月','5月','6月','7月','8月']}
			if(this.$refs.bar1) {
				this.$refs.bar1.initData(
					[
						{name: '冷链物流总额', type: 'bar', color: 'green', data: _bardata.bar,dw:'亿元',yAxisIndex:0},
						{name: '增长率', type: 'line', color: 'purple', data: _bardata.line,dw:'%',yAxisIndex:1,labelColor:'#623aff'},
					],
					_bardata.xaxis
				);
			}

			let _bardata2 = {bar:[100,200,300,400,1000,200,500,300],line:[0,23,58.3,39,13,66,44,29],xaxis:['1月','2月','3月','4月','5月','6月','7月','8月']}
			if(this.$refs.bar2) {
				this.$refs.bar2.initData(
					[
						{name: '冷链物流总额', type: 'line', color: 'green', data: _bardata2.bar,dw:'亿元',yAxisIndex:0,showArea:true,labelColor:'#39ffdc'},
						{name: '增长率', type: 'line', color: 'purple', data: _bardata2.line,dw:'%',yAxisIndex:1,showArea:true,labelColor:'#623aff'},
					],
					_bardata2.xaxis
				);
			}

			let aa = [100,200,300,400,1000,200,500,300];
			let bb = ['1月','2月','3月','4月','5月','6月','7月','8月'];
			let _pie1data = [];

			aa.forEach((it,ii)=>{
				_pie1data.push({value: it, name: bb[ii]})
			})

			if(this.$refs.pie1) {
				this.$refs.pie1.initData(_pie1data);
			}
		}
	}
}
</script>
