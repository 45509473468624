<template>
    <lee-page bg-img="/images/bg_head_8.jpg" title="行业数据" sub-title="Industry Data" des="" :search="$isMobile">
        <template v-if="$isMobile">
            <div class="padding-left-right">
                <el-row :gutter="10" class="margin-top">
                    <el-col :span="12" v-for="(item,index) in hyList">
                        <div class="cl bg-light border-radius-5 rel hide box-shadow padding-20 margin-bottom round-bg" @click="$router.push({path:item.path})">
                            <lee-img-icon :url="item.icon" class="z" color="#7ED321" size="40"></lee-img-icon>
                            <div class="z f20 dark-2 f-lq margin-left rel" style="line-height: 40px;">{{item.title}}</div>
                        </div>
                    </el-col>
                </el-row>
            </div>
        </template>
        <template v-else>
            <div class="bg-img-5 text-center padding-bottom-400">
                <div class="cl"></div>
                <span class="rel inline-block margin-top-100">
                    <img src="/images/img-map2.png" height="550"/>
                    <lee-map-hot size="204" top="26" left="194" @click.native="$router.push({path:'/hydata/coldstorage'})"></lee-map-hot>
<!--	                /hydata/coldcar-->
                  <lee-map-hot size="204" top="26" left="490" @click.native="$router.push({path:'/hydata/coldchain'})"></lee-map-hot>
<!--	                coldstorage-->
                  <lee-map-hot size="204" top="26" left="785" @click.native="$router.push({path:'/hydata/cabinet'})"></lee-map-hot>
<!--                  <lee-map-hot size="204" top="26" left="1081" @click.native="$router.push({path:'/hydata/cabinet'})"></lee-map-hot>-->
                  <lee-map-hot size="204" top="319" left="26" @click.native="$router.push({path:'/hydata/inout'})"></lee-map-hot>
<!--	                harvest-->
                    <lee-map-hot size="204" top="320" left="322" @click.native="$router.push({path:'/hydata/coldcar'})"></lee-map-hot>
                    <lee-map-hot size="204" top="320" left="618" @click.native="$router.push({path:'/hydata/harvest'})"></lee-map-hot>
<!--                    <lee-map-hot size="204" top="320" left="914" @click.native="$router.push({path:'/hydata/characteristic'})"></lee-map-hot>-->
                </span>
            </div>
        </template>
    </lee-page>
</template>

<script>
export default {
    name: "Index",
    data(){
        return{
            hyList:[
                {title:'冷藏车',icon:'/images/icon/icon_9_on.png',path:'/hydata/coldcar'},
                {title:'冷库',icon:'/images/icon/icon_10_on.png',path:'/hydata/coldstorage'},
                {title:'冷链物流',icon:'/images/icon/icon_11_on.png',path:'/hydata/coldchain'},
                {title:'产量',icon:'/images/icon/icon_12_on.png',path:'/hydata/harvest'},
                {title:'进出口',icon:'/images/icon/icon_13_on.png',path:'/hydata/inout'},
                {title:'农批',icon:'/images/icon/icon_14_on.png',path:'/hydata/agriculture'},
                {title:'特殊农品',icon:'/images/icon/icon_15_on.png',path:'/hydata/characteristic'},
            ]
        }
    },
    methods:{
        prevSwiper(){
            this.$refs['swiper'].prev();
        },
        nextSwiper(){
            this.$refs['swiper'].next();
        }
    }
}
</script>
