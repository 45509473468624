<template>
    <lee-page bg-img="/images/bg_head_7.jpg" title="重点企业" sub-title="Key Enterprises" des="">
        <div style="margin-top:-65px;height: 130px;" class="rel wrap1100 cl">
            <div class="z padding-right" style="width: 820px;">
                <div>
                    <el-row :gutter="10">
                        <el-col :span="8">
                            <lee-select v-model="searchForm.year" class="bg-light" :options="searchYearList">
                            </lee-select>
                        </el-col>
                        <el-col :span="8">
                            <lee-select v-model="searchForm.type_nature_id" class="bg-light" :options="searchNatureList"></lee-select>
                        </el-col>
                        <el-col :span="8">
	                        <lee-select v-model="searchForm.person_count" class="bg-light" :options="personCountList"></lee-select>
<!--                            <lee-type-select v-model="searchForm.person_count" class="bg-light" akey="person" placeholder="人员规模"></lee-type-select>-->
                        </el-col>
                    </el-row>
                </div>
                <div class="margin-top">
                    <el-row :gutter="10">
                        <el-col :span="8">
                            <lee-select v-model="orderType" @change="onChangeSortBy" :options="[{label:'默认排序',value:0},{label:'排名上升',value:1},{label:'排名下降',value:2}]"></lee-select>
                        </el-col>
                        <el-col :span="7">
<!--                            <lee-select v-model="searchForm.business" :options="[{label:'选择主营业务',value:''},{label:'配送型业务',value:'peisong'},{label:'保税型业务',value:'baoshui'},{label:'园区型业务',value:'yuanqu'},{label:'批发市场型业务',value:'pifa'}]"></lee-select>-->
                          <lee-select v-model="searchForm.business" :options="[{label:'选择主营业务',value:''},{label:'运输',value:'peisong'},{label:'仓储',value:'baoshui'},{label:'综合',value:'yuanqu'},{label:'其他',value:'pifa'}]"></lee-select>
                        </el-col>
                        <el-col :span="9">
                            <lee-input placeholder="输入企业名称" v-model="searchForm.title">
                                <el-button class="f20 padding-0 green f-lq" slot="append" size="small" type="text" icon="el-icon-s-promotion" @click="toSearch">查询</el-button>
                            </lee-input>
                        </el-col>
                    </el-row>
                </div>
            </div>
            <div class="y" style="width: 280px;">
                <div class="bg-green light border-radius-5 box-shadow-green cl hand" style="height:130px;padding:30px 15px;" @click="$router.push({path:'/company/report/'+searchForm.year})">
                    <lee-img-icon url="/images/icon/icon_24.png" class="z"></lee-img-icon>
                    <div class="z f24 margin-left-20 f-lq">查看{{searchForm.year}}<br/>重点企业报告</div>
                </div>
            </div>
        </div>
        <div class="bg-img-2 padding-bottom-400" style="margin-top:-65px;padding-top:65px;background-color: #f8f9fa;">
            <div class="cl"></div>
            <div class="wrap1100 cl margin-top-30">
                <div class="z" :class="tabIndex === 1 ? 'tab-on' : 'tab-off'" @click="changeTab(1)">重点企业</div>
                <div class="z margin-left" :class="tabIndex === 2 ? 'tab-on' : 'tab-off'" @click="changeTab(2)">报表</div>
            </div>
            <div class="wrap1100 bg-light border-radius-5 padding" v-show="tabIndex === 1">
                <template v-if="list.length > 0">
                    <div class="cl padding-top-bottom border-bottom company-item" v-for="(item,index) in list">
                        <span class="z line-index bg-green light f16" v-if="item.arank > 3">{{item.arank}}</span>
                        <img :src="'/images/icon/jiangbei_' + item.arank+'.png'" width="30" height="30" class="z" v-else/>
                        <div class="z margin-left f22 f-thin">{{item.title}}</div>
                        <div class="y green f20 margin-left hand text-right" style="width: 60px;" @click="$router.push({path:'/company/detail/'+item.id})" v-if="item.is_open === 1">详情</div>
                      <div class="y dark-3 f20 margin-left text-right" style="width: 60px;" v-else>未公开</div>
                        <lee-updown-tag class="y margin-right" :number="item.arank_change" :isnew="item.is_new === 1"></lee-updown-tag>
                    </div>
                </template>
                <div class="f16 padding-top-bottom-40 dark-2 text-center" v-else>
                    暂无数据
                </div>
            </div>
            <div class="wrap1100 bg-light border-radius-5 padding" v-show="tabIndex === 2">
                <table class="lee-table margin-top-30 margin-auto" style="width: 100%;">
                    <tr class="title">
                        <td>企业名称</td>
                        <td>排名</td>
                        <td>排名变化</td>
                        <td>营业收入(万元)</td>
                        <td>冷藏车总量(辆)</td>
                        <td>冷库总容积(立方米)</td>
                        <td></td>
                    </tr>
                    <tr class="hand" v-for="(item,index) in baiqiangList">
                        <td>{{item.title}}</td>
                        <td>{{item.arank}}</td>
                        <td align="center"><lee-updown-tag :number="item.arank_change" :isnew="item.is_new === 1"></lee-updown-tag></td>
                      <td>
	                      -
<!--                        <template v-if="item.is_open === 1">{{item.amount}}</template>-->
<!--                        <template v-else>-</template>-->
                      </td>
                        <td>
                          <template v-if="item.is_open === 1">{{item.car}}</template>
                          <template v-else>-</template>
                        </td>
                        <td>
                          <template v-if="item.is_open === 1">{{item.lengku}}</template>
                          <template v-else>-</template>
                        </td>
                        <td>
                            <div class="green f20 hand" @click="$router.push({path:'/company/detail/'+item.id})" v-if="item.is_open === 1">详情</div>
                          <div class="dark-3 f20" v-else>未公开</div>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
    </lee-page>
</template>

<script>
export default {
    name: "Index",
    data(){
        return{
            searchForm:{
                year:'',
                orderBy:'arank',
                sortBy:'asc',
	            person_count:'',
                type_nature_id:'',
                title:'',
                business:''
            },
          orderType:0,
            searchYearList:[],
            searchNatureList:[],
	        personCountList:[
		        {label:'人员规模',value:''},
		        {label:'0-500人',value:'0,500'},
		        {label:'500-1000人',value:'501,1000'},
		        {label:'1000-2000人',value:'1001,2000'},
		        {label:'2000-5000人',value:'2001,5000'},
		        {label:'5000-10000人',value:'5001,10000'},
		        {label:'10000人以上',value:'10000'},
	        ],
            page:1,
            pageSize:20,
            list:[],
            baiqiangList:[],
            nowYear:'',
            staffNumberList:[
                {label:'人员规模',value:''},
                {label:'0-100',value:1},
                {label:'100-500',value:2},
                {label:'500-1000',value:3},
                {label:'1000以上',value:4},
            ],
            tabIndex:1
        }
    },
    created() {
        let _now = new Date();
        let _start_year = parseInt(_now.getFullYear() - 1);
        let _end_year = parseInt(_start_year - 20);

        this.nowYear = _start_year;
        // this.searchForm.year = _start_year;

        // this.searchYearList.push({label:'年份',value:''});
        //
        // for(let i = _start_year;i > _end_year;i--){
        //     this.searchYearList.push({label:i.toString(),value:i.toString()});
        // }
    },
    mounted() {
        this.getYears();
        // this.getList();
        this.getNatureList();
    },
    methods:{
        changeTab(i){
            this.tabIndex = i;
        },
      onChangeSortBy(e){
        if(e === 0){
          this.searchForm.orderBy = 'arank';
          this.searchForm.sortBy = 'asc';
        }else{
          this.searchForm.orderBy = 'arank_change';
          this.searchForm.sortBy = e === 1 ? 'desc' : 'asc';
        }
      },
        getList(){
            let _this = this;
            this.$http.post({
                url: '/Baiqiang/listAll',
                data: this.searchForm,
                success(res) {
                    _this.list = res;

                    _this.baiqiangList = [];

                    for(let i = 0;i < res.length;i++){
                        let _lengku = res[i].lengku_rl ? parseFloat(res[i].lengku_rl) : 0;

                        // _lengku += parseFloat(res[i].changwenku);
                        // _lengku += parseFloat(res[i].chaodiwenku);
                        // _lengku += parseFloat(res[i].lengcangku);
                        // _lengku += parseFloat(res[i].lengdongku);

                        let _car = 0;
                        _car += res[i].heavy_car;
                        _car += res[i].light_car;
                        _car += res[i].mini_car;
                        _car += res[i].out_car;
                        _car += res[i].small_car;
                        _car += res[i].owner_car;

                        let _data = {
                          id:res[i].id,
                          title:res[i].title,
                          arank:res[i].arank,
                          arank_change:res[i].arank_change,
                          amount:res[i].amount,
                          year:res[i].year,
                          lengku:_lengku,
                          car:_car,
                          is_new:res[i].is_new,
                          is_open:res[i].is_open
                        }
                        _this.baiqiangList.push(_data);
                    }
                }
            });
        },
        getYears(){
            let _this = this;
            this.$http.post({
                url: '/Baiqiang/getYears',
                data: null,
                success(res) {
                  console.log(res);
                  for (let i=0;i < res.length;i++){
                    _this.searchYearList.push({label:res[i]+'年',value:res[i]});
                  }

                  _this.searchForm.year = res[0] ? res[0] : _this.nowYear;

                  _this.getList();
                    // _this.searchYearList = res;
                }
            });
        },
        getNatureList(){
            let _this = this;
            this.$http.post({
                url: '/Type/listAll',
                data: {akey:'nature'},
                success(res) {
                    _this.searchNatureList.push({label:'企业性质',value:''});

                    for(let i=0;i<res.length;i++){
                        _this.searchNatureList.push({label:res[i].name,value:res[i].id});
                    }
                    // console.log(res);
                    // _this.list = res.data;
                }
            });
        },
        toSearch(){
            this.getList();
        }
    }
}
</script>
<style scoped>
.line-index{width:30px;height:30px;text-align: center;line-height: 30px;display: inline-block;border-radius: 15px;}
.company-item{line-height: 30px;}

.tab-on,.tab-off{cursor:pointer;padding:0 40px;height:40px;line-height: 40px;font-size:16px;text-align: center;border-radius: 10px 10px 0 0;}

.tab-on{background:#fff;color:#7ed321;}
.tab-off{background:#eee;color:#777;}
</style>
