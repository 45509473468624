<template>
    <lee-page bg-img="/images/bg_head_9.jpg" type="light" title-class="margin-top-100" height="280" title="冷链物流相关数据" :bread="[{title:'行业数据',url:'/hydata'},{title:'冷链物流'}]">
        <div class="bg-img-2 padding-bottom-400 padding-top-50" :style="{backgroundColor:'#f8f9fa'}">
            <check-vip akey="hangyedata_chain">
                <div class="wrap1100">
                    <lee-year-tab :list="yearList"></lee-year-tab>
                    <div class="box-shadow padding-30 bg-light margin-top-30">
                        <div class="green-line">
                            <div class="cl f34">
                                <lee-img-icon url="/images/icon/icon_31.png" size="30" color="#7ED321" class="z margin-right" v-if="!$isMobile"></lee-img-icon>
                                <div class="z" :class="$isMobile ? 'f18 margin-top dark-2 block' : 'f22'">冷链物流相关数据</div>
                            </div>
<!--                            <el-row :gutter="20" class="dark-3 margin-top" :class="$isMobile ? 'f12' : 'f14'">-->
<!--                                <el-col :md="5" :xs="12">发布机构：湖北省政府</el-col>-->
<!--                                <el-col :md="5" :xs="12">发布时间：2020-03-26</el-col>-->
<!--                                <el-col :md="5" :xs="12">文号：国药监注[2019]33号</el-col>-->
<!--                            </el-row>-->
                        </div>
                        <div class="f16 dark-2" v-if="data">
                            近{{data.list.length}}年冷链物流总体情况如下：
                        </div>
                      <div class="text-center">
                        <lee-bar-echarts
                          ref="bar10"
                          :grid-bottom="$isMobile ? 50 : 30"
                          :height="$isMobile ? '250px' : '350px'"
                          show-legend
                        ></lee-bar-echarts>
                      </div>
<!--                        <div class="text-center">-->
<!--                            <lee-bar-echarts-->
<!--                                ref="hebing"-->
<!--                                :grid-bottom="$isMobile ? 50 : 30"-->
<!--                                :height="$isMobile ? '250px' : '350px'"-->
<!--                                show-legend-->
<!--                            ></lee-bar-echarts>-->
<!--                        </div>-->
                        <div class="f16 dark-2">
                            <p v-if="data">{{nowYear}}年，中物联冷链委通过对蔬菜、水果、肉类、水产品、乳制品和速冻米面这六大类食品（其它类食品产量较少或基本不采用冷链物流方式流通，故没有计算在内）的年产量进行统计，并结合各品类的冷链流通率，测算{{nowYear}}年我国食品冷链物流需求总量为{{data.now.total}}亿吨，比{{nowYear - 1}}年{{parseFloat(data.now.total) - parseFloat(data.last.total) >= 0 ? '增长' : '减少'}}{{ Math.abs(parseFloat(data.now.total) - parseFloat(data.last.total)).toFixed(2)}}万吨，同比{{data.now.rate >= 0 ? '增长' : '减少'}}{{Math.abs(data.now.rate)}}%。
                            </p>
                            <div class="text-center">
                                <lee-bar-echarts
                                    ref="bar1"
                                    :grid-bottom="$isMobile ? 50 : 30"
                                    :height="$isMobile ? '250px' : '350px'"
                                    show-legend
                                ></lee-bar-echarts>
                            </div>
                            <p v-if="data">其中，蔬菜冷链物流需求总量为{{data.now.shucai}}万吨，水果冷链物流需求总量为{{data.now.shuiguo}}万吨，肉类冷链物流需求总量为{{data.now.rouzhipin}}万吨，水产品冷链物流需求总量为{{data.now.shuichanpin}}万吨，乳制品冷链物流需求总量为{{data.now.ruzhipin}}万吨，速冻米面冷链物流需求总量为{{data.now.sudongshipin}}万吨。</p>
                            <div class="margin-auto" :style="{width: $isMobile ? '100%' : '600px'}">
                                <lee-pie-echarts
                                    ref="pie1"
                                    :grid-bottom="$isMobile ? 150 : 30"
                                    :height="$isMobile ? '350px' : '350px'"
                                    :label-format="'{b}\n{d}%'"
                                    label-pos="outside"
                                    :legend-orient="$isMobile ? 'horizontal' : 'vertical'"
                                    :legend-pos="$isMobile ? 'bottom' : 'right'"
                                    :radius="['0%','60%']"></lee-pie-echarts>
                            </div>
                            <p v-if="data">按照冷链食品平均价格与{{nowYear-1}}年持平的条件下计算，{{nowYear}}年我国食品冷链物流总额约为{{data.now.lenglian_amount}}万亿元，比{{nowYear-1}}年同比{{data.now.lenglian_rate >= 0 ? '增长' : '减少'}}{{data.now.lenglian_rate}}%。</p>
                            <div class="text-center">
                                <lee-bar-echarts
                                    ref="bar2"
                                    :grid-bottom="$isMobile ? 50 : 30"
                                    :height="$isMobile ? '250px' : '350px'"
                                    show-legend
                                    :data="[
                                    {name:'冷链物流总额(万亿)',type:'bar',color:'green',data:[3740,4200,4750,5238,6052.5]},
                                    {name:'增长率',type:'line',color:'purple',data:[12.7,12.3,14,10.3,15.56]}]"
                                    :x-axis="['2015', '2016', '2017', '2018', '2019']"
                                ></lee-bar-echarts>
                            </div>
                            <p v-if="data">根据{{nowYear}}年我国冷链物流总额和冷链物流费用占比情况，中国物流与采购联合会冷链物流专业委员会分析测算，{{nowYear}}年我国冷链物流市场总规模为{{data.now.wlsc_amount}}亿元，比{{nowYear-1}}年增长{{(parseFloat(data.now.wlsc_amount) - parseFloat(data.last.wlsc_amount)).toFixed(2)}}亿元，同比增长{{data.now.wlsc_rate}}%。</p>
                            <div class="text-center">
                                <lee-bar-echarts
                                    ref="bar3"
                                    :grid-bottom="$isMobile ? 50 : 30"
                                    :height="$isMobile ? '250px' : '350px'"
                                    show-legend
                                    :data="[
                                    {name:'冷链物流业市场规模',type:'bar',color:'green',data:[3740,4200,4750,5238,6052.5]},
                                    {name:'增长率',type:'line',color:'purple',data:[12.7,12.3,14,10.3,15.56]}]"
                                    :x-axis="['2015', '2016', '2017', '2018', '2019']"
                                ></lee-bar-echarts>
                            </div>
                        </div>
                    </div>
                </div>
            </check-vip>
        </div>
    </lee-page>
</template>

<script>
export default {
    name: "List",
    data(){
        return{
            yearList:[],
            nowYear:'',

            startYear:'',
            endYear:'',

            data:null,
        }
    },
    created() {
        let _this = this;

        let _now = new Date();

        this.$nextTick(()=>{
            // _this.nowYear = _this.$route.query.year ? parseInt(_this.$route.query.year) : (_this.yearList.length > 0 ? _this.yearList[0].value : _now.getFullYear() - 1);
        })
        this.nowYear = this.$route.query.year ? this.$route.query.year : '';
        // this.nowYear = this.$route.query.year ? this.$route.query.year : this.yearList[0].value;
    },
    mounted() {
        this.getYears();
    },
    methods:{
        getYears() {
            let _this = this;

            this.$http.post({
                url: '/HydataLenglian/getYears',
                data:null,
                success(res) {
                    for (let i = 0;i < res.length;i++){
                        _this.yearList.push({label:res[i]+'年',value:res[i]});
                    }

                    _this.nowYear = _this.nowYear ? _this.nowYear : res[0];
                    _this.endYear = res[res.length - 1];

                    _this.getTotal1(_this.nowYear);
                }
            });
        },

        getTotal1(year){
            //enterprise/statistics
            let _this = this;
            this.$http.post({
                url:'/HydataLenglian/getData',
                data:{year:year,yearlist:[year,year - 4]},
                success(res){
                    _this.data = res;

                    let _bardata = {bar:[],line:[],xaxis:[]};
                    let _bar2data = {bar:[],line:[],xaxis:[]};
                    let _bar3data = {bar:[],line:[],xaxis:[]};

                    let _hebing = {bar1:[],bar2:[],xaxis:[]};
                  let _bar10 = {bar:[],line:[],xaxis:[]};

                    for(let i=0;i < res.list.length;i++) {
                        _bardata.bar.push(res.list[i].total);
                        _bardata.line.push(res.list[i].rate);
                        _bardata.xaxis.push(res.list[i].year.toString());

                        _bar2data.bar.push(res.list[i].lenglian_amount);
                        _bar2data.line.push(res.list[i].lenglian_rate);
                        _bar2data.xaxis.push(res.list[i].year.toString());

                        _bar3data.bar.push(res.list[i].wlsc_amount);
                        _bar3data.line.push(res.list[i].wlsc_rate);
                        _bar3data.xaxis.push(res.list[i].year.toString());

                        _hebing.xaxis.push(res.list[i].year.toString());

                        _hebing.bar1.push(res.list[i].total);
                        _hebing.bar2.push(res.list[i].lenglian_amount);

                      _bar10.bar.push(res.list[i].shehui_amount);
                      _bar10.line.push(res.list[i].shehui_rate);
                      _bar10.xaxis.push(res.list[i].year.toString());
                    }

                    if(_this.$refs.hebing) {
                        _this.$refs.hebing.initData(
                            [
                                {name: '冷链物流需求总量', type: 'bar', color: 'green', data: _hebing.bar1,dw:'万吨'},
                                {name: '冷链物流总额', type: 'bar', color: 'blue', data: _hebing.bar2,dw:'亿元'},
                                // {name: '社会物流总额', type: 'bar', color: 'orange', data: _hebing.bar3},
                            ],
                            _hebing.xaxis
                        );
                    }

                  if(_this.$refs.bar10) {
                    _this.$refs.bar10.initData(
                        [
                          {name: '社会物流总额', type: 'bar', color: 'green', data: _bar10.bar,dw:'亿元'},
                          {name: '增长率', type: 'line', color: 'purple', data: _bar10.line,dw:'%'},
                        ],
                        _bar10.xaxis
                    );
                  }

                    if(_this.$refs.bar1) {
                        _this.$refs.bar1.initData(
                            [
                                {name: '冷链物流需求总量', type: 'bar', color: 'green', data: _bardata.bar,dw:'亿元'},
                                {name: '增长率', type: 'line', color: 'purple', data: _bardata.line,dw:'%'},
                            ],
                            _bardata.xaxis
                        );
                    }

                    if(_this.$refs.bar2) {
                        _this.$refs.bar2.initData(
                            [
                                {name: '冷链物流总额', type: 'bar', color: 'green', data: _bar2data.bar,dw:'亿元'},
                                {name: '增长率', type: 'line', color: 'purple', data: _bar2data.line,dw:'%'},
                            ],
                            _bar2data.xaxis
                        );
                    }

                    if(_this.$refs.bar3) {
                        _this.$refs.bar3.initData(
                            [
                                {name: '冷链物流业市场规模', type: 'bar', color: 'green', data: _bar3data.bar,dw:'亿元'},
                                {name: '增长率', type: 'line', color: 'purple', data: _bar3data.line,dw:'%'},
                            ],
                            _bar3data.xaxis
                        );
                    }

                    let _pie1Data = [];

                    _pie1Data.push({value:res.now.sudongshipin,name:'速冻食品'});
                    _pie1Data.push({value:res.now.ruzhipin,name:'乳制品'});
                    _pie1Data.push({value:res.now.shuichanpin,name:'水产品'});
                    _pie1Data.push({value:res.now.rouzhipin,name:'肉制品'});
                    _pie1Data.push({value:res.now.shuiguo,name:'水果'});
                    _pie1Data.push({value:res.now.shucai,name:'蔬菜'});

                    if(_this.$refs.pie1) {
                        _this.$refs.pie1.initData(_pie1Data);
                    }
                }
            })
        },
    }
}
</script>
